import React from 'react';

const EditIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.53869 12.264C9.53869 13.0166 8.9286 13.6267 8.17602 13.6267C7.42345 13.6267 6.81335 13.0166 6.81335 12.264C6.81335 11.5115 7.42345 10.9014 8.17602 10.9014C8.9286 10.9014 9.53869 11.5115 9.53869 12.264Z" fill="white"/>
  <path d="M9.53869 16.352C9.53869 17.1046 8.9286 17.7147 8.17602 17.7147C7.42345 17.7147 6.81335 17.1046 6.81335 16.352C6.81335 15.5994 7.42345 14.9894 8.17602 14.9894C8.9286 14.9894 9.53869 15.5994 9.53869 16.352Z" fill="white"/>
  <path d="M13.6267 16.352C13.6267 17.1046 13.0166 17.7147 12.264 17.7147C11.5114 17.7147 10.9013 17.1046 10.9013 16.352C10.9013 15.5994 11.5114 14.9894 12.264 14.9894C13.0166 14.9894 13.6267 15.5994 13.6267 16.352Z" fill="white"/>
  <path d="M13.6267 12.264C13.6267 13.0166 13.0166 13.6267 12.264 13.6267C11.5114 13.6267 10.9013 13.0166 10.9013 12.264C10.9013 11.5115 11.5114 10.9014 12.264 10.9014C13.0166 10.9014 13.6267 11.5115 13.6267 12.264Z" fill="white"/>
  <path d="M17.7147 12.264C17.7147 13.0166 17.1046 13.6267 16.352 13.6267C15.5994 13.6267 14.9893 13.0166 14.9893 12.264C14.9893 11.5115 15.5994 10.9014 16.352 10.9014C17.1046 10.9014 17.7147 11.5115 17.7147 12.264Z" fill="white"/>
  <path d="M17.7147 16.352C17.7147 17.1046 17.1046 17.7147 16.352 17.7147C15.5994 17.7147 14.9893 17.1046 14.9893 16.352C14.9893 15.5994 15.5994 14.9894 16.352 14.9894C17.1046 14.9894 17.7147 15.5994 17.7147 16.352Z" fill="white"/>
  <path d="M21.8027 12.264C21.8027 13.0166 21.1926 13.6267 20.44 13.6267C19.6874 13.6267 19.0774 13.0166 19.0774 12.264C19.0774 11.5115 19.6874 10.9014 20.44 10.9014C21.1926 10.9014 21.8027 11.5115 21.8027 12.264Z" fill="white"/>
  <path d="M21.8027 16.352C21.8027 17.1046 21.1926 17.7147 20.44 17.7147C19.6874 17.7147 19.0774 17.1046 19.0774 16.352C19.0774 15.5994 19.6874 14.9894 20.44 14.9894C21.1926 14.9894 21.8027 15.5994 21.8027 16.352Z" fill="white"/>
  <path d="M25.8907 12.264C25.8907 13.0166 25.2806 13.6267 24.528 13.6267C23.7754 13.6267 23.1654 13.0166 23.1654 12.264C23.1654 11.5115 23.7754 10.9014 24.528 10.9014C25.2806 10.9014 25.8907 11.5115 25.8907 12.264Z" fill="white"/>
  <path d="M25.8907 16.352C25.8907 17.1046 25.2806 17.7147 24.528 17.7147C23.7754 17.7147 23.1654 17.1046 23.1654 16.352C23.1654 15.5994 23.7754 14.9894 24.528 14.9894C25.2806 14.9894 25.8907 15.5994 25.8907 16.352Z" fill="white"/>
  <path d="M2.72534 14.9894C2.72534 11.1352 2.72534 9.20805 3.92269 8.0107C5.12004 6.81335 7.04714 6.81335 10.9014 6.81335H21.8027C25.6569 6.81335 27.584 6.81335 28.7813 8.0107C29.9787 9.20805 29.9787 11.1352 29.9787 14.9894V17.7147C29.9787 21.5689 29.9787 23.496 28.7813 24.6933C27.584 25.8907 25.6569 25.8907 21.8027 25.8907H10.9014C7.04714 25.8907 5.12004 25.8907 3.92269 24.6933C2.72534 23.496 2.72534 21.5689 2.72534 17.7147V14.9894Z" stroke="white" strokeWidth="3.75"/>
  <path d="M9.53867 21.8027H23.1653" stroke="white" strokeWidth="3.75" strokeLinecap="round"/>
  </svg>
  
  );
};

export default EditIcon;
