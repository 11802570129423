import React, { useState, useEffect } from 'react';
import ImageControlUiToolbar from '@/pages/Maker/components/ToolbarPhoto/ImageControlUiToolbar';
import { getThumbnails } from '@/dexie/dbHelpers';
import PhotoGallery from './PhotoGallery';

const PhotoToolbar = () => {
  const [images, setImages] = useState([]);
  const [galleryVisible, setGalleryVisible] = useState(false);

  const refreshImages = async () => {
    const dbImages = await getThumbnails();
    setImages(dbImages);
  };

  useEffect(() => {
    refreshImages();
  }, []);

  return (
    <div>
      <ImageControlUiToolbar 
        onUploadComplete={refreshImages} 
        setGalleryVisible={setGalleryVisible}
        galleryVisible={galleryVisible}
        />
      {/* <ImageCarouselToolbar images={images} setImages={setImages} /> */}
      {galleryVisible && images && <PhotoGallery 
        images={images}
        setImages={setImages}
        setGalleryVisible={setGalleryVisible}
        />}
    </div>
  );
};


export default PhotoToolbar;
