import {db} from './db';

async function createThumbnail(file) {

  const imageFile = file;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 150;
        canvas.height = 150;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, 150, 150);
        canvas.toBlob(blob => resolve(new File([blob], imageFile.name, { type: imageFile.type })), imageFile.type, 0.7);
      };
      img.onerror = () => {
        reject(new Error(`Error loading image of type ${imageFile.type}. The file type might not be supported or the file may be corrupt.`));
      };
      img.src = event.target.result as string;
    };
    reader.onerror = () => {
      reject(new Error(`Error reading the file of type ${imageFile.type}.`));
    };
    reader.readAsDataURL(imageFile);
  });
}

const saveImage = async (imageName: string, file: File) => {
  // Convert image to base64
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const [fullSizeImage] = await new Promise((resolve) => {
    reader.onload = () => {
      const base64Image = reader.result.toString();
      resolve([base64Image]);
    };
  });

  // Generate thumbnail
  const thumbnail = await createThumbnail(file);

  // Save full size image to IndexedDB
  const savedImage = await db.images.add({
    fullSizeImage,
    fileName: file.name,
    fileType: file.type,
  });

  // Save thumbnail to IndexedDB
  await db.thumbnails.add({
    imageName,
    thumbnail,
    imageId: savedImage,
  });

  return savedImage;
};


// Function to add an image and its thumbnail to the database
async function addImageAndThumbnail(name, file) {
  // Create a thumbnail from the image file
  const thumbnailBlob = await createThumbnail(file);

  // Add the full-size image to the "images" collection
  const imageId = await db.images.add({ name, file });

  // Add the thumbnail to the "thumbnails" collection, with the same ID as the image
  await db.thumbnails.add({ id: imageId, name, file: thumbnailBlob });

  return imageId;
}

// Function to get all the thumbnails from the database
async function getThumbnails() {
  return db.thumbnails.toArray();
}

const saveThumbnail = async (imageName: string, thumbnail: string) => {
  await db.thumbnails.add({
    imageName,
    thumbnail,
  });
};

async function deleteThumbnail(id) {
    return db.thumbnails.where({ id: id }).delete();
}

// These are my new db functions

async function deleteImage(id) {
  await db.thumbnails.where({ id: id }).delete();
  await db.images.where({ id: id }).delete();
}


async function getFullSizeImageFromById(id) {
  return await db.images.get(id);
}

async function dumpDatabase() {
  try {
    const allImages = await db.images.toArray();
    const allThumbnails = await db.thumbnails.toArray();
    console.log("All images:", allImages);
    console.log("All thumbnails:", allThumbnails);
  } catch (error) {
    console.log("Error dumping database:", error);
  }
}

async function clearDatabase() {
  try {
    await db.images.clear();
    await db.thumbnails.clear();
    console.log("Database cleared");
  } catch (error) {
    console.log("Error clearing database:", error);
  }
}



export { createThumbnail, addImageAndThumbnail, getThumbnails, saveImage, saveThumbnail, deleteThumbnail };
export { getFullSizeImageFromById, dumpDatabase, clearDatabase, deleteImage }