import FabricCanvas from "@/pages/Maker/components/FabricCanvas/FabricCanvas";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
// import skuObjectLookup from "../../utils/skuObjectLookup";
// import Box from '@mui/material/Box';
import { Box } from "@mui/material";
import theme from '@/theme/theme';
// hooks
import useFontLoader from "@/hooks/useFonts";
import { useSkuConfig } from '@/hooks/useSkuConfig'
import shipstationProjectAtom from "@/atoms/shipstation-project-data-atom";
import { Processing } from "@/components/Processing";
import { useSerializedFabricCanvasJSON, useSvgFile } from "@/hooks";
// admin page load
import ConfigMaker from "@/pages/admin/ConfigMaker";
// signals
import { canvas } from "@/signals/canvas";

const MyTv = forwardRef((props, ref) => {
  console.log('render MyTv')
  // Trying to load a different json when in edit mode

  const boxRef = useRef(null);
  const {fontsLoaded, isLoading: isFontsLoading, isError: fontError } = useFontLoader();

  console.log("MyTv", 'useSkuConfig')
  const { config, isLoading: isSkuConfigLoading, isError: skuConfigError } = useSkuConfig();

  const handleFabricCanvasClick = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the Box
  };

  const deselectAllObjects = () => {
    canvas.value.discardActiveObject()
    canvas.value.renderAll();    
  }

  // Handler for the Escape key press
  const handleEscapeKeyPress = (event) => {
    if (event.key === 'Escape') {
      canvas.value.discardActiveObject()
      canvas.value.renderAll();    
    }
  };

  useEffect(() => {
    // Add event listener for keydown events on mount
    window.addEventListener('keydown', handleEscapeKeyPress);

    // Cleanup by removing the event listener on component unmount
    return () => window.removeEventListener('keydown', handleEscapeKeyPress);
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount
  
  let fileData = ''

  console.log('MYTV config', config)
  
  // load svg or json based on the default_file_type from the config
  console.log('attempting to load svg file MYTV', config?.default_file_type === 'svg')
  const {data: svgFileData, error: svgFileError, isLoading: isSvgFileLoading} = useSvgFile(config?.default_file_type === 'svg')
  console.log('attempting to load json file', config?.default_file_type === 'json')
  const {data: fabricJsonFileData, error: fabricJSONError, isLoading: isFabricJSONLoading} = useSerializedFabricCanvasJSON(config?.default_file_type === 'json')

// Load the box so the UI doesn't load janky.
  if(isSvgFileLoading || isFabricJSONLoading) return (
    <Box ref={boxRef}
      className="my-tv"
      sx={{
        flexGrow: 1,
        display: "flex",
        overflow: "hidden",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[200]
      }}
    >
      <Processing message="Loading The Artwork Files..." />
    </Box>
  )

  // if there are no art files, then they need to be uploaded
  if(!svgFileData && !fabricJsonFileData) return (<>
    <h1>There are no art files for this product. Please Contact Support.</h1>
    <p>App is looking for an: {config.default_file_type}</p>
    </>)

  console.log('MYTV skuConfigError', skuConfigError, config)


  fileData = config.default_file_type === 'svg' ? svgFileData : fabricJsonFileData
  let fileType = config.default_file_type === 'svg' ? 'svg' : 'json'

  // I added a Box and not just a Processing component
  // becuase I didn't want the page behind wonky while the resources are loading
  if (isSkuConfigLoading || isFontsLoading) return (
    <Box ref={boxRef}
      className="my-tv"
      sx={{
        flexGrow: 1,
        display: "flex",
        overflow: "hidden",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[200]
      }}
    >
      <Processing message="Loading Resources..." />
    </Box>
  )


  // if (error) return `An error has occurred: ${error.message}`

  // Consolidate error checks
  const error = skuConfigError || svgFileError || ( fontError && new Error("Fonts loading error"));

  // if (error) {
  //   // Render error message for any error
  //   return <ConfigMaker error={error} />
  //   // return <div>An error occurred: {error.message}</div>;
  // }

  if(!config) return `There is no config for this product`

  


  
  return <>
    <Box ref={boxRef}
      className="my-tv"
      sx={{
        flexGrow: 1,
        display: "flex",
        overflow: "hidden",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[200]
      }}
      onClick={deselectAllObjects}
    >
      <div onClick={handleFabricCanvasClick}>
        <FabricCanvas
          boxRef={boxRef}        
          fileData={fileData}
          fileType={fileType}
          />
    </div>
      </Box>
  </>
})

MyTv.displayName = "MyTv";
export default MyTv;