import Button from "./Button";

const NavyButton = ({ children, onClick, icon, ...rest }) => (
  <Button 
    icon={icon}
    variant="navy"
    onClick={onClick}
    {...rest}
    >
    {children}
  </Button>
);

export default NavyButton;