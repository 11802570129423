// swatchData.js

export const mainSwatches = [
  // {name: 'grid', src: 'sw-grid.png', fullSizeDimension: 6000, webSizeDimension: 1200}, // This swatch is for testing only
  { name: 'marble', src: 'sw-marble.jpg', fullSizeDimension: 3600 },
  { name: 'burlap', src: 'sw-burlap.jpg', fullSizeDimension: 800 },
  { name: 'coffeewood', src: 'sw-coffeewood.jpg', fullSizeDimension: 3000 },
  { name: 'plaid', src: 'sw-plaid.jpg', fullSizeDimension: 400 },
  { name: 'chalkboard', src: 'sw-chalkboard.jpg', fullSizeDimension: 1700 },
  { name: 'shabby', src: 'sw-shabby.jpg', fullSizeDimension: 2048 },
  { name: 'richwood', src: 'sw-richwood.jpg', fullSizeDimension: 5080 },
  { name: 'ivorywood', src: 'sw-ivorywood.jpg', fullSizeDimension: 2048 },
  { name: 'vanilla', src: 'sw-vanilla.jpg', fullSizeDimension: 2048 },
  { name: 'darkwood', src: 'sw-darkwood.jpg', fullSizeDimension: 5080 },
  { name: 'lightwood', src: 'sw-lightwood.jpg', fullSizeDimension: 2048 },
  { name: 'plank', src: 'sw-plank.jpg', fullSizeDimension: 2048 },
  { name: 'silver', src: 'sw-silver.jpg', fullSizeDimension: 3600 },
  { name: 'golden', src: 'sw-golden.jpg', fullSizeDimension: 3600 }
];

export const floralSwatches = [
  { name: 'f1', src: 'sw-f1.jpg', fullSizeDimension: 3600, webSizeDimension: 1200 },
  { name: 'f2', src: 'sw-f2.jpg', fullSizeDimension: 3600 },
  { name: 'f3', src: 'sw-f3.jpg', fullSizeDimension: 3600 },
  { name: 'f4', src: 'sw-f4.jpg', fullSizeDimension: 3600 },
  { name: 'f5', src: 'sw-f5.jpg', fullSizeDimension: 3600 }
];

export const frameSwatches = [
  { name: 'bg2', src: 'sw-frame-bg2-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg3', src: 'sw-frame-bg3-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg4', src: 'sw-frame-bg4-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg5', src: 'sw-frame-bg5-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg6', src: 'sw-frame-bg6-300.jpg', fullSizeDimension: 3075 }
];


