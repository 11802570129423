import React from 'react';

const LowerCaseIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2361 19.9597C15.5448 19.9597 14.9174 19.8295 14.354 19.5691C13.7905 19.3039 13.3431 18.9228 13.0116 18.4256C12.6802 17.9237 12.5145 17.3177 12.5145 16.6074C12.5145 15.9824 12.6376 15.4758 12.8838 15.0876C13.13 14.6946 13.4591 14.3868 13.871 14.1643C14.2829 13.9417 14.7375 13.776 15.2347 13.6671C15.7365 13.5535 16.2408 13.4635 16.7474 13.3972C17.4103 13.312 17.9477 13.2481 18.3597 13.2054C18.7763 13.1581 19.0793 13.08 19.2687 12.9711C19.4629 12.8622 19.5599 12.6728 19.5599 12.4029V12.3461C19.5599 11.6453 19.3682 11.1008 18.9847 10.7126C18.6059 10.3243 18.0306 10.1302 17.2588 10.1302C16.4586 10.1302 15.8312 10.3054 15.3767 10.6557C14.9222 11.0061 14.6025 11.3802 14.4179 11.7779L12.827 11.2097C13.1111 10.5468 13.4899 10.0307 13.9633 9.66142C14.4416 9.28736 14.9624 9.02695 15.5258 8.88017C16.094 8.72865 16.6527 8.65289 17.202 8.65289C17.5524 8.65289 17.9548 8.69551 18.4094 8.78073C18.8686 8.86123 19.3114 9.02931 19.7375 9.28499C20.1684 9.54068 20.5258 9.92657 20.8099 10.4427C21.094 10.9588 21.2361 11.6501 21.2361 12.5165V19.704H19.5599V18.2268H19.4747C19.3611 18.4635 19.1717 18.7168 18.9065 18.9867C18.6414 19.2566 18.2886 19.4862 17.8483 19.6756C17.4079 19.865 16.8705 19.9597 16.2361 19.9597ZM16.4918 18.454C17.1546 18.454 17.7133 18.3238 18.1679 18.0634C18.6272 17.803 18.9728 17.4668 19.2048 17.0549C19.4416 16.643 19.5599 16.2097 19.5599 15.7552V14.2211C19.4889 14.3063 19.3327 14.3844 19.0912 14.4554C18.8544 14.5217 18.5798 14.5809 18.2673 14.633C17.9596 14.6804 17.6589 14.723 17.3653 14.7608C17.0765 14.794 16.8421 14.8224 16.6622 14.8461C16.2266 14.9029 15.8194 14.9952 15.4406 15.1231C15.0666 15.2462 14.7635 15.4332 14.5315 15.6841C14.3043 15.9304 14.1906 16.2665 14.1906 16.6927C14.1906 17.2751 14.4061 17.7154 14.8369 18.0137C15.2725 18.3073 15.8241 18.454 16.4918 18.454Z" fill="white"/>
<path d="M4.96654 19.9597C4.27525 19.9597 3.64789 19.8295 3.08444 19.5691C2.52099 19.3039 2.07355 18.9228 1.74211 18.4256C1.41067 17.9237 1.24495 17.3177 1.24495 16.6074C1.24495 15.9824 1.36806 15.4758 1.61427 15.0876C1.86048 14.6946 2.18955 14.3868 2.60148 14.1643C3.01342 13.9417 3.46796 13.776 3.96512 13.6671C4.46701 13.5535 4.97128 13.4635 5.4779 13.3972C6.14078 13.312 6.67819 13.2481 7.09012 13.2054C7.50679 13.1581 7.80982 13.08 7.99921 12.9711C8.19334 12.8622 8.2904 12.6728 8.2904 12.4029V12.3461C8.2904 11.6453 8.09864 11.1008 7.71512 10.7126C7.33633 10.3243 6.76105 10.1302 5.98927 10.1302C5.18908 10.1302 4.56171 10.3054 4.10717 10.6557C3.65262 11.0061 3.33302 11.3802 3.14836 11.7779L1.55745 11.2097C1.84154 10.5468 2.22033 10.0307 2.69381 9.66142C3.17203 9.28736 3.69287 9.02695 4.25631 8.88017C4.82449 8.72865 5.38321 8.65289 5.93245 8.65289C6.28283 8.65289 6.68529 8.69551 7.13984 8.78073C7.59912 8.86123 8.04182 9.02931 8.46796 9.28499C8.89883 9.54068 9.25631 9.92657 9.5404 10.4427C9.8245 10.9588 9.96654 11.6501 9.96654 12.5165V19.704H8.2904V18.2268H8.20518C8.09154 18.4635 7.90215 18.7168 7.63699 18.9867C7.37184 19.2566 7.0191 19.4862 6.57876 19.6756C6.13842 19.865 5.60101 19.9597 4.96654 19.9597ZM5.22222 18.454C5.8851 18.454 6.44381 18.3238 6.89836 18.0634C7.35764 17.803 7.70328 17.4668 7.93529 17.0549C8.17203 16.643 8.2904 16.2097 8.2904 15.7552V14.2211C8.21938 14.3063 8.06313 14.3844 7.82165 14.4554C7.58491 14.5217 7.31029 14.5809 6.99779 14.633C6.69002 14.6804 6.38936 14.723 6.0958 14.7608C5.80698 14.794 5.5726 14.8224 5.39268 14.8461C4.95707 14.9029 4.54987 14.9952 4.17109 15.1231C3.79703 15.2462 3.494 15.4332 3.26199 15.6841C3.03472 15.9304 2.92109 16.2665 2.92109 16.6927C2.92109 17.2751 3.13652 17.7154 3.56739 18.0137C4.003 18.3073 4.55461 18.454 5.22222 18.454Z" fill="white"/>
</svg>

  );
};

export default LowerCaseIcon;
