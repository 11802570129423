// signals
import { canvas } from "@/signals/canvas";
// components
import { NavyButton } from "@/components/Button";

const LogActiveSelectionButton = () => {

  const logActive = () => {
    console.log("Active Object:", canvas.value.getActiveObject());
  };

  return (
    <NavyButton
    primary
    onClick={() => logActive()}
  >
    Log Active Selection
  </NavyButton>
  )
}

export default LogActiveSelectionButton