import { Button } from '@/components/Button';
import canvasAtom from '@/atoms/canvas-atom';
import { useRecoilState } from 'recoil';
import * as fabric from 'fabric'; // Assuming fabric is correctly imported
import { Image } from 'fabric'; // Make sure to import Image from 'fabric'

const AddUploadPhotosButton = () => {
  const [canvas, setCanvas] = useRecoilState(canvasAtom);

  const addUploadPhotos = async (e) => {
    e.preventDefault();
    const defaultUploadImageUrl = 'https://peachwik-app.s3.amazonaws.com/app-images/upload.png';

    const originalImg = await Image.fromURL(defaultUploadImageUrl, { crossOrigin: 'Anonymous' });
    console.log('Original image loaded:', originalImg);

    for (const obj of canvas.getObjects()) {
      if (obj.id?.includes('photo')) {
        obj.set({ fill: 'transparent' });
        console.log('obj:', obj)
        // Create a new image instance with the same source as the original
        const img = new fabric.Image(originalImg.getElement(), { crossOrigin: 'Anonymous' });
        // Scaling the image to fit the object's area
        const scale = Math.max(obj.width / img.width, obj.height / img.height);
        img.scale(scale);

        // Calculate the position to center the image
        const imgLeft = obj.left + (obj.width / 2) - (img.getScaledWidth() / 2);
        const imgTop = obj.top + (obj.height / 2) - (img.getScaledHeight() / 2);
        
        obj.absolutePositioned = true;

        // Positioning the image at the object's position
        img.set({
          originX: 'left',
          originY: 'top',
          left: imgLeft,
          top: imgTop,
          angle: obj.angle,
          clipPath: obj,
          clipPathID: obj.id, // If you have a clipping path associated
          selectable: false,
          evented: false,
        });

        // Add the image to the canvas
        canvas.add(img);

        // Get the index of obj
        const objIndex = canvas.getObjects().indexOf(obj);
        // Move the image to just above the obj
        canvas.moveObjectTo(img, objIndex + 1);

      }
    }

    // find the canvas object with the id of background and change it to transparent
    const background = canvas.getObjects().find(obj => obj.id === 'background');
    if (background?.fill === '#BADA55') {
      background.set({ fill: 'transparent' });
    }    

    // Update canvas state and render
    setCanvas(canvas);
    canvas.renderAll();
  };

  return (
    <Button
      variant="contained"
      onClick={(e) => addUploadPhotos(e)}
    >
      Add Upload Photos
    </Button>
  );
}

export default AddUploadPhotosButton;
