import { useState, useEffect } from "react";
import WebFont from "webfontloader";
import fonts from "../utils/fonts";
import { useRecoilValue } from "recoil";
import appModeAtom from "@/atoms/app-mode-atom";

const fontFamilies = fonts.map((font) => font.font_family);

const useFontLoader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const appMode = useRecoilValue(appModeAtom)

  useEffect(() => {
    if(appMode === "ADMIN" || appMode === "SHIPSTATION")
    WebFont.load({
      custom: {
        families: fontFamilies,      },
      active: () => {
        setFontsLoaded(true);
        setIsLoading(false);
      },
      inactive: () => {
        setFontsLoaded(true);
        setIsLoading(false);
      },
    });
    else {
      setIsError(true)
      setFontsLoaded(true)
      setIsLoading(false);
    }
  }, []);
  return {fontsLoaded, isLoading, isError};
};

export default useFontLoader;
