import React from 'react';

const FontSizeIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.9531 12.1875H18.0469C17.918 12.1875 17.8125 12.293 17.8125 12.4219V15.7031C17.8125 15.832 17.918 15.9375 18.0469 15.9375H19.4531C19.582 15.9375 19.6875 15.832 19.6875 15.7031V14.0625H21.4453V23.4375H20.0977C19.9688 23.4375 19.8633 23.543 19.8633 23.6719V25.0781C19.8633 25.207 19.9688 25.3125 20.0977 25.3125H24.9023C25.0312 25.3125 25.1367 25.207 25.1367 25.0781V23.6719C25.1367 23.543 25.0312 23.4375 24.9023 23.4375H23.5547V14.0625H25.3125V15.7031C25.3125 15.832 25.418 15.9375 25.5469 15.9375H26.9531C27.082 15.9375 27.1875 15.832 27.1875 15.7031V12.4219C27.1875 12.293 27.082 12.1875 26.9531 12.1875ZM19.2188 8.67188V4.92188C19.2188 4.79297 19.1133 4.6875 18.9844 4.6875H3.04688C2.91797 4.6875 2.8125 4.79297 2.8125 4.92188V8.67188C2.8125 8.80078 2.91797 8.90625 3.04688 8.90625H4.6875C4.81641 8.90625 4.92188 8.80078 4.92188 8.67188V6.79688H9.84375V23.2031H7.14844C7.01953 23.2031 6.91406 23.3086 6.91406 23.4375V25.0781C6.91406 25.207 7.01953 25.3125 7.14844 25.3125H14.8828C15.0117 25.3125 15.1172 25.207 15.1172 25.0781V23.4375C15.1172 23.3086 15.0117 23.2031 14.8828 23.2031H12.1875V6.79688H17.1094V8.67188C17.1094 8.80078 17.2148 8.90625 17.3438 8.90625H18.9844C19.1133 8.90625 19.2188 8.80078 19.2188 8.67188Z" fill="white"/>
</svg>

  );
};

export default FontSizeIcon;
