// This takes the fabric canvas object directly, not the signal
const uploadCanvasImagesToS3 = async (canvas) => {
  console.log("Uploading canvas images to S3...");

  const urls = [];

  const uploadPromises = canvas.getObjects().filter(obj => obj.type === "image").map(async (object) => {
    const src = object.getSrc();
    console.log("src:", src);

    const urlParts = src.split("/");
    const fullFilename = urlParts.pop();
    const filenameParts = fullFilename.split(".");
    const fileExtension = filenameParts.length > 1 ? filenameParts.pop().toLowerCase() : 'png';
    const filename = filenameParts.join('.');
    console.log("filename:", filename, "Extension:", fileExtension);

    const blob = await fetch(src).then((res) => res.blob());

    const mimeTypes = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      webp: 'image/webp',
      heic: 'image/heic',

      // Add other formats as needed
    };
    const fileType = mimeTypes[fileExtension] || 'application/octet-stream';

    const response = await fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url`, {
      method: 'POST',
      body: JSON.stringify({
        filename: `${filename}.${fileExtension}`,
        filetype: fileType,
      }),
    });

    const { url } = await response.json();

    const result = await fetch(url, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': fileType,
      },
    });

    if (result.ok) {
      const s3Url = url.split('?')[0];
      urls.push(s3Url);

      console.log(`Uploading to S3 succeeded, URL: ${s3Url}`);
      try {
        await object.setSrc(s3Url, {
          crossOrigin: 'anonymous'
        });
        console.log(`Canvas object source updated to ${s3Url}`);
        canvas.renderAll();
      } catch (error) {
        console.error(`Error updating canvas object source: ${error}`);
      }
    } else {
      const text = await result.text();
      console.error(`Upload failed for: ${filename}, Error: ${text}`);
      throw new Error('Upload failed');
    }
  });

  const updatedUrls = await Promise.all(uploadPromises);

  return urls.concat(updatedUrls);
};

export default uploadCanvasImagesToS3;
