import Debug from "@/pages/Maker/components/Headers/Debug";
import { forwardRef, useState } from "react";
import { AppBar } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import React from "react";
import ButtonGroup from "@/components/ButtonGroup";
import { NavyButton } from "@/components/Button"
import ToggleEdgeButton from "@/components/ToggleEdgeButton/ToggleEdgeButton";
import axios from "axios";
// import ZoomButton from "@/components/UIControls/ZoomButton";
// import PanControls from "@/components/UIControls/PanControls";
import FormData from 'form-data';
import appModeAtom from "@/atoms/app-mode-atom";

import Processing from "@/components/Processing/Processing";
// For selecting objects on the canvas by their ID
import { makeSelection } from "@/utils/select"
// atoms
import appStateAtom from "@/atoms/app-state-atom";
import toolbarAtom from "@/atoms/toolbar-atom";
// components
import RecoilDebugButton from "./RecoilDebugButton";
import LogCanvasButton from "./LogCanvasButton";
// hooks
import { useSkuConfig } from "@/hooks";
// Signals
import { sku } from "@/signals/sku";

// This is for add to cart 
import { generateThumbnailAndUploadToS3, serializeCanvasAndUploadToS3, uploadCanvasImagesToS3 } from "@/utils";
import ZoomSlider from "../ZoomSlider";
import SaveFabricJSONButton from "@/pages/admin/components/SaveFabricJSONButton";

// signals
import { canvas } from "@/signals/canvas";

// components
import WsmSaveButton from "./WsmSaveButton";
import LogActiveSelectionButton from "./LogActiveSelectionButton";
import SaveSVGButton from "./SaveSVGButton";
import SavePNGButton from "./SavePNGButton";
import LogCanvasObjects from "./LogCanvasObjects";


const AdminHeader = forwardRef((props, ref) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [debug, setDebug] = useState(false)
  const [appMode, setAppMode] = useRecoilState(appModeAtom)
  const [toolbar, setToolbar] = useRecoilState(toolbarAtom)

  // custom hooks
  const { config } = useSkuConfig();

  // functions start here



  const handleClose = () => {
    var app = document.querySelector('.app-outer') || document.querySelector('#peachwik-app');
    app?.classList.toggle('hidden');
    document.body.style.overflow = 'unset';

    // Unmount the React app
    window.removeReactApp();
  }

  return (
    <header ref={ref}>
      <AppBar position="static">
        <h1>Sku: {sku}</h1>
        <h1>appMode: {appMode}</h1>
        <h1>FileType: {config.default_file_type}</h1>
        <h1>File: {config.default_file_type === 'svg' ? config.svg : config.file}</h1>
        <h1>Toolbar: {toolbar}</h1>
        <Debug
          visible={debug}
          setDebug={setDebug}
        />
        {/* align content of toolbar on the right */}
          <ButtonGroup>
            <RecoilDebugButton />
            <WsmSaveButton />
            <SaveSVGButton />
            <SavePNGButton />
          </ButtonGroup>

          <ButtonGroup>
            {/* <ZoomSlider /> */}
            {/* <ZoomButton /> */}
            {/* <PanControls /> */}
            <SaveFabricJSONButton />
            <LogCanvasButton />
            <LogCanvasObjects />
            <LogActiveSelectionButton />

            <NavyButton
              onClick={() => setDebug(!debug)}
            >CONFIG</NavyButton>

          </ButtonGroup>


          {isProcessing && <Processing message="Uploading Images for your order..." />}



          {/* window.add_to_cart is defined in shopify, params are canvas and thumbnail */}

          {/* 
            Only show this button if the sku includes 'canvas
          */}
          {/* { sku && sku.includes('canvas') && <ToggleEdgeButton />} */}

      </AppBar>
    </header>
  );
})

AdminHeader.displayName = "AdminHeader";
export default AdminHeader;

