import React from 'react';

const ZoomIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_3229_440" fill="white">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4919 17.3087C21.8143 13.9756 21.5518 9.36039 18.6383 6.30812C15.3126 2.82412 9.79233 2.69575 6.30833 6.02138C2.82434 9.34701 2.69596 14.8673 6.02159 18.3513C8.7616 21.2218 12.9914 21.8144 16.3447 20.0741L25.2546 29.4084L28.2826 26.518L19.4919 17.3087Z"/>
    </mask>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4919 17.3087C21.8143 13.9756 21.5518 9.36039 18.6383 6.30812C15.3126 2.82412 9.79233 2.69575 6.30833 6.02138C2.82434 9.34701 2.69596 14.8673 6.02159 18.3513C8.7616 21.2218 12.9914 21.8144 16.3447 20.0741L25.2546 29.4084L28.2826 26.518L19.4919 17.3087Z" fill="white"/>
    <path d="M19.4919 17.3087L18.6714 16.737L18.2041 17.4078L18.7686 17.9992L19.4919 17.3087ZM16.3447 20.0741L17.068 19.3837L16.55 18.8409L15.884 19.1865L16.3447 20.0741ZM25.2546 29.4084L24.5313 30.0988L25.2217 30.8222L25.9451 30.1317L25.2546 29.4084ZM28.2826 26.518L28.9731 27.2413L29.6964 26.5509L29.006 25.8275L28.2826 26.518ZM17.9149 6.9986C20.4935 9.70003 20.7273 13.7864 18.6714 16.737L20.3124 17.8804C22.9013 14.1647 22.61 9.02075 19.3616 5.61764L17.9149 6.9986ZM6.99881 6.74473C10.0833 3.80044 14.9706 3.9141 17.9149 6.9986L19.3616 5.61764C15.6546 1.73415 9.50135 1.59105 5.61786 5.29802L6.99881 6.74473ZM6.74495 17.6608C3.80065 14.5763 3.91431 9.68903 6.99881 6.74473L5.61786 5.29802C1.73436 9.00499 1.59126 15.1583 5.29824 19.0418L6.74495 17.6608ZM15.884 19.1865C12.9147 20.7276 9.16976 20.2011 6.74495 17.6608L5.29824 19.0418C8.35345 22.2425 13.0681 22.9012 16.8053 20.9617L15.884 19.1865ZM25.978 28.7179L17.068 19.3837L15.6213 20.7646L24.5313 30.0988L25.978 28.7179ZM27.5921 25.7946L24.5641 28.685L25.9451 30.1317L28.9731 27.2413L27.5921 25.7946ZM18.7686 17.9992L27.5593 27.2085L29.006 25.8275L20.2153 16.6182L18.7686 17.9992Z" fill="white" mask="url(#path-1-inside-1_3229_440)"/>
    <ellipse cx="12.3337" cy="12.3307" rx="7.32558" ry="7.32558" transform="rotate(-43.6678 12.3337 12.3307)" fill="#819A98"/>
    </svg>    
  );
};

export default ZoomIcon;
