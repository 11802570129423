import React from 'react';

const CenterAlignIcon = () => {
  return (
    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M0 2.20404C0 1.37561 0.671573 0.704041 1.5 0.704041H28.5C29.3284 0.704041 30 1.37561 30 2.20404C30 3.03247 29.3284 3.70404 28.5 3.70404H1.5C0.671573 3.70404 0 3.03247 0 2.20404ZM0 8.20404C0 7.37561 0.671573 6.70404 1.5 6.70404H28.5C29.3284 6.70404 30 7.37561 30 8.20404C30 9.03247 29.3284 9.70404 28.5 9.70404H1.5C0.671573 9.70404 0 9.03247 0 8.20404ZM0 14.204C0 13.3756 0.671573 12.704 1.5 12.704H28.5C29.3284 12.704 30 13.3756 30 14.204C30 15.0325 29.3284 15.704 28.5 15.704H1.5C0.671573 15.704 0 15.0325 0 14.204ZM0 20.204C0 19.3756 0.671573 18.704 1.5 18.704H28.5C29.3284 18.704 30 19.3756 30 20.204C30 21.0325 29.3284 21.704 28.5 21.704H1.5C0.671573 21.704 0 21.0325 0 20.204ZM6 26.204C6 25.3756 6.67157 24.704 7.5 24.704H22.5C23.3284 24.704 24 25.3756 24 26.204C24 27.0325 23.3284 27.704 22.5 27.704H7.5C6.67157 27.704 6 27.0325 6 26.204Z" fill="white"/>
</svg>

  );
};

export default CenterAlignIcon;
