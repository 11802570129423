// appModeAtom.js
import { atom } from 'recoil';

const appModeAtom = atom({
  key: 'appMode',
  // default: 'SHOPIFY',  // Set a default mode [SHOPIFY, ADMIN, SHIPSTATION]
  default: 'ADMIN',  // Set a default mode [SHOPIFY, ADMIN, SHIPSTATION]
  // default: 'SHIPSTATION',  // Set a default mode [SHOPIFY, ADMIN, SHIPSTATION]
});

export default appModeAtom;