import React from 'react';
import { atom, useRecoilState, useRecoilCallback } from 'recoil';
import { Button } from '@/components/Button';

// Example atom, ensure you have at least one atom in your application
const testAtom = atom({
  key: 'testAtom',
  default: 'Initial value',
});

function RecoilDebugButton() {
  const [testValue, setTestValue] = useRecoilState(testAtom); // Example usage of an atom
  
  const onClick = useRecoilCallback(({snapshot}) => async () => {
    console.log('Callback triggered');
    for (const node of snapshot.getNodes_UNSTABLE()) {
      const value = await snapshot.getPromise(node);
      console.log(node.key, value);
    }
  }, []);

  return <Button onClick={onClick}>Dump RECOIL State</Button>;
}


export default RecoilDebugButton;