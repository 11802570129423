import React from 'react';

const CenterAlignDocumentIcon = () => {
  return (
    <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5349 8.72093C19.5349 7.41681 19.5349 6.76476 19.2544 6.27907C19.0708 5.96089 18.8065 5.69667 18.4884 5.51297C18.0027 5.23256 17.3506 5.23256 16.0465 5.23256H10.814V1.04651C10.814 0.468544 10.3454 0 9.76744 0C9.18949 0 8.72093 0.468544 8.72093 1.04651V5.23256H3.48837C2.18425 5.23256 1.5322 5.23256 1.04651 5.51297C0.72833 5.69667 0.464107 5.96089 0.28041 6.27907C2.28715e-07 6.76475 0 7.41681 0 8.72093C0 10.025 2.28715e-07 10.6771 0.28041 11.1628C0.464107 11.481 0.72833 11.7452 1.04651 11.9289C1.5322 12.2093 2.18425 12.2093 3.48837 12.2093H8.72093V17.7907H6.27907C4.97495 17.7907 4.3229 17.7907 3.83721 18.0712C3.51903 18.2548 3.2548 18.5191 3.07111 18.8372C2.7907 19.3229 2.7907 19.975 2.7907 21.2791C2.7907 22.5832 2.7907 23.2352 3.07111 23.7209C3.2548 24.0391 3.51903 24.3033 3.83721 24.487C4.3229 24.7674 4.97495 24.7674 6.27907 24.7674H8.72093V28.9535C8.72093 29.5314 9.18949 30 9.76744 30C10.3454 30 10.814 29.5314 10.814 28.9535V24.7674H13.2558C14.5599 24.7674 15.212 24.7674 15.6977 24.487C16.0158 24.3033 16.2801 24.0391 16.4637 23.7209C16.7442 23.2352 16.7442 22.5832 16.7442 21.2791C16.7442 19.975 16.7442 19.3229 16.4637 18.8372C16.2801 18.5191 16.0158 18.2548 15.6977 18.0712C15.212 17.7907 14.5599 17.7907 13.2558 17.7907H10.814V12.2093H16.0465C17.3506 12.2093 18.0027 12.2093 18.4884 11.9289C18.8065 11.7452 19.0708 11.481 19.2544 11.1628C19.5349 10.6771 19.5349 10.0251 19.5349 8.72093Z" fill="white"/>
</svg>

  );
};

export default CenterAlignDocumentIcon;
