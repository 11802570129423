// signals
import { canvas } from "@/signals/canvas";
// components
import { NavyButton } from "@/components/Button";

const LogCanvasButton = () => {

  const logCanvas = () => {
    console.log("Canvas:", canvas.value);
  };

  return (
    <NavyButton
    onClick={() => logCanvas()}
  >
    Log Canvas
  </NavyButton>
  );
}

export default LogCanvasButton;