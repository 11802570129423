// signals
import { NavyButton } from "@/components/Button";
import { canvas } from "@/signals/canvas";  


const LogCanvasObjects = ({  }) => {
  return (
    <NavyButton onClick={() => console.log(canvas.value.getObjects())}>Log Canvas Objects</NavyButton>
  )
}

export default LogCanvasObjects