import React, { useState } from 'react';
import { Image, Group } from 'fabric'; // Make sure to import Image from 'fabric'
import { getFullSizeImageFromById, deleteImage } from '@/dexie/dbHelpers'; // Import your database helper function
// utils
import { makeSelection } from '@/utils/select';
// ui
import { Button } from '@/components/Button';
import ButtonGroup from '@/components/ButtonGroup';
import { Alert } from '@/components/Dialog';
import PhotoInstructions from './PhotoInstructions';
// components
import PhotoCard from './PhotoCard';
// signals
import { canvas } from '@/signals/canvas';

const PhotoGallery = ({ images, setImages, setGalleryVisible }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // Add selectedImage state
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const hideAlert = () => {
    setIsAlertVisible(false);
  };

  const handleImageClick = (index) => {
    if (selectedImageIndex === index) {
      // If the clicked image is already selected, deselect it
      setSelectedImageIndex(null);
      setSelectedImage(null); // Clear selectedImage
    } else {
      // If a different image is clicked, select it
      setSelectedImageIndex(index);
      setSelectedImage(images[index]); // Set the selected image
    }
  };

  const handleAdd = async () => {

    // I'm guessing this is like this because sometimes the object is selected programmatically and 
    // sometimes it is selected by the user clicking on it
    const activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    // this is needed so that the selection is updated if direct selection is made
    makeSelection(canvas, activeObjectID)

    // delete the existing image if there is one
    // This is to deal with group or direct selection
    const activeObject = canvas.value.getActiveObject();
    if (activeObject && activeObject.type === 'activeSelection') {
      for (const obj of activeObject.getObjects()) {
        console.log('removing obj:', obj);
        // Check all objects on the canvas
        for (const canvasObj of canvas.value.getObjects()) {
          // If the canvas object is an image and its clipPathID matches our activeObject's ID
          if (canvasObj.type === 'image' && canvasObj.clipPathID === obj.id) {
            canvas.value.remove(canvasObj); // Remove the old image
          }
        }
      }
      canvas.value.renderAll();
    }

    const image = selectedImage

    try {
      // 

      // Step 1: Load Image
      const img = await Image.fromURL(URL.createObjectURL(image.thumbnail), { crossOrigin: 'Anonymous' });


      const fullSizeImageRecord = await getFullSizeImageFromById(image.id);
      console.log('fullSizeImageRecord:', fullSizeImageRecord)
      console.log('fullSizeImageRecord.id:', fullSizeImageRecord.id)
      // console.log('fullSizeImageRecord.file:', fullSizeImageRecord.fullSizeImage)

      if (!fullSizeImageRecord || !fullSizeImageRecord) {
        throw new Error('Full-size image not found');
      }

      function base64ToBlob(base64, mimeType) {
        const bytes = atob(base64.split(',')[1]);
        const array = new Uint8Array(bytes.length);
        for (let i = 0; i < bytes.length; i++) {
          array[i] = bytes.charCodeAt(i);
        }
        return new Blob([array], { type: mimeType });
      }

      const blob = base64ToBlob(fullSizeImageRecord.fullSizeImage, 'image/jpeg'); // replace 'image/jpeg' with the actual MIME type if different
      const fullSizeImage = await Image.fromURL(URL.createObjectURL(blob), { crossOrigin: 'Anonymous' });

      // Step 2: Checking Active Object
      const activeGroupOrObject = canvas.value.getActiveObject();
      if (!activeGroupOrObject) return;

      // Step 3: Checking Original Path
      const originalClipPath = activeGroupOrObject.item(0);
      // trying to make sure the ClipPath does not get selected
      // and does not block the image from being selected
      originalClipPath.evented = true;
      
      // Step 3.5: Get the z index of the originalClipPath and log it out here
      // const zIndexOriginalClipPath = canvas.value.getObjects().indexOf(originalClipPath);
      // console.log("Z-index of originalClipPath:", zIndexOriginalClipPath);

      // Step 4: Scaling the Image
      const scaleW = originalClipPath.width / fullSizeImage.width;
      const scaleH = originalClipPath.height / fullSizeImage.height;
      const scale = Math.max(scaleW, scaleH);
      fullSizeImage.set({ scaleX: scale, scaleY: scale });

      // Step 4.5: 
      fullSizeImage.clipPathID = originalClipPath.id;
      // const scale2 = 72/300;
      // fullSizeImage.set({ scaleX: scale2, scaleY: scale2 });

      // Step 5: Positioning the Image
      // Calculate the position of the image relative to the canvas
      const groupTop = activeGroupOrObject.get('top');
      const groupLeft = activeGroupOrObject.get('left');

      fullSizeImage.set({
        top: groupTop,
        left: groupLeft
      });

      originalClipPath.absolutePositioned = true;

      // canvas.value.remove(originalClipPath)
      fullSizeImage.clipPath = originalClipPath;

      // trying to make sure fullSizeImage is not selectable
      fullSizeImage.selectable = false;
      fullSizeImage.evented = false;

      fullSizeImage.on('mousedown', (options) => {
        console.log('Image clicked', options);
      });

      canvas.value.add(fullSizeImage);

      // Get the index of obj(clipPath)
      const clipPathIndex = canvas.value.getObjects().indexOf(originalClipPath);
      // Move the image to just above the obj(clipPath)
      canvas.value.moveObjectTo(fullSizeImage, clipPathIndex + 1);


      canvas.value.renderAll();
      setGalleryVisible(false);

    } catch (error) {
      console.error("Error in handleAdd:", error);
    }
  };

  const handleDelete = async () => {
    if (selectedImageIndex === null) {
      console.warn("No image selected to delete.");
      return;
    }

    // Remove image from the Fabric.js canvas
    const selectedImg = canvas.value.getObjects().find(
      (obj) => obj.type === "image" && obj._element.src === selectedImage.src
    );
    if (selectedImg) {
      canvas.value.remove(selectedImg);
      canvas.value.renderAll();
    }

    // Remove image from Dexie database
    try {
      await deleteImage(selectedImage.id);
    } catch (error) {
      console.error("Failed to delete image from Dexie:", error);
    }

    // Remove image from state
    const newImages = [...images];
    newImages.splice(selectedImageIndex, 1);
    setImages(newImages);

    // Reset selection
    setSelectedImageIndex(null);
    setSelectedImage(null);
  };



  return (
    <>

      {isAlertVisible && (
        <Alert
          title="Instructions"
          message={<PhotoInstructions />}
          buttonLabel="OK"
          onConfirm={() => {
            hideAlert();
          }}
        />
      )}

      <div className='tw-bg-peachwik-mint-light'>
        <ButtonGroup>
          <Button
            variant='navy'
            onClick={() => {
              document.getElementById("image-btn").click()
            }
            }>
            UPLOAD
          </Button>
          <Button
            variant='navy orb'
            onClick={() => setIsAlertVisible(true)}
          >?</Button>
        </ButtonGroup>
      </div>
      {images.length > 0 &&
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            height: '150px',
          }}
          className='tw-overflow-x-auto'
        >
          {images.map((image, index) => (
            <PhotoCard
              key={image.thumbnail}
              index={index}
              image={image}
              selectedImageIndex={selectedImageIndex}
              handleImageClick={handleImageClick}
              handleAdd={handleAdd}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      }
    </>
  );
};

export default PhotoGallery;
