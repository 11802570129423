import React, { useState } from 'react';
import { Toolbar } from '@mui/material';
import ButtonGroup from '@/components/ButtonGroup';
import { Button } from '@/components/Button';
import { RabbitIcon, TurtleIcon } from '@/components/Icons';
// signals
import { canvas } from '@/signals/canvas';

const NudgeToolbar = () => {


  const [speed, setSpeed] = useState(1)


  const handleNudge = (direction) => {
    const activeObject = canvas.value.getActiveObject()
    if (activeObject === null) return
    const { left, top } = activeObject
    switch (direction) {
      case 'left':
        activeObject.set({ left: left - 1 })
        break
      case 'right':
        activeObject.set({ left: left + 1 })
        break
      case 'up':
        activeObject.set({ top: top - 1 })
        break
      case 'down':
        activeObject.set({ top: top + 1 })
        break
      default:
        break
    }
    canvas.value.renderAll()
  }


  return <Toolbar className="tw-justify-center tw-items-center">
    <ButtonGroup>
      <Button
        variant="navy orb up"
        onClick={() => handleNudge('up')}
      >&#x2794;</Button>
      <Button
        variant="navy orb down"
        onClick={() => handleNudge('down')}
      >&#x2794;</Button>
      <Button
        variant="navy orb left"
        onClick={() => handleNudge('left')}
      >&#x2794;</Button>
      <Button
        variant="navy orb right"
        onClick={() => handleNudge('right')}
      >&#x2794;</Button>

      <Button
        variant="navy icon-stacked"
        onClick={() => setSpeed(5)}
        icon={<RabbitIcon />}
      >Fast</Button>

      <Button
        variant="navy icon-stacked"
        onClick={() => setSpeed(1)}
        icon={<TurtleIcon />}
      >Slow</Button>

    </ButtonGroup>
  </Toolbar>
}

export default NudgeToolbar