import React from 'react';

const PhotoIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 13.75C11.3807 13.75 12.5 12.6307 12.5 11.25C12.5 9.86929 11.3807 8.75 10 8.75C8.61929 8.75 7.5 9.86929 7.5 11.25C7.5 12.6307 8.61929 13.75 10 13.75Z" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.20069 26.25C15.1631 11.125 20.9506 8.47498 27.5006 18.2875" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.5 3.75H7.5C4.73857 3.75 2.5 5.98857 2.5 8.75V21.25C2.5 24.0114 4.73857 26.25 7.5 26.25H22.5C25.2614 26.25 27.5 24.0114 27.5 21.25V8.75C27.5 5.98857 25.2614 3.75 22.5 3.75Z" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};

export default PhotoIcon;
