import { signal } from "@preact/signals-react";

// sku will be stored in window.sku when the app is laoded in Shopify

// export const sku = signal(window.sku || "wsm1004-sm-matte");
// export const sku = signal(window.sku || "wsm1003-sm-matte")
// export const sku = signal(window.sku || "wsm1003-sm-foam")
// export const sku = signal(window.sku || "wsm1003-sm-canvas")
// export const sku = signal(window.sku || "wsm1003-md-matte"); // bad
// export const sku = signal(window.sku || "wsm1003-md-foam")
// export const sku = signal(window.sku || "wsm1003-md-canvas")
// export const sku = signal(window.sku || "wsm1003-lg-matte");
// export const sku = signal(window.sku || "wsm1003-lg-foam")
// export const sku = signal(window.sku || "wsm1003-lg-canvas")

;
// export const sku = signal(window.sku || "wsm1003-sm-matte");
// export const sku = signal(window.sku || "wsm1003-lg-canvas");
export const sku = signal(window.sku || "gba1001-50-canvas");
// export const sku = signal(window.sku || "fpp1001");