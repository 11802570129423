import { Paper, Slider, Toolbar } from "@mui/material";
import { useRecoilState } from "recoil";
import { useState } from "react";
import Typography from "@mui/material/Typography";
// signals
import { canvas } from "@/signals/canvas";

function LineHeightToolbar() {
  let activeObject = canvas.value.getActiveObject();
  // Check if the selection is a group or a single item if it's a group pick the first object
  if (activeObject?._objects && activeObject._objects.length > 0) {
    activeObject = activeObject._objects[0];
  }
  // get line height from active object
  const [lineHeight, setLineHeight] = useState(activeObject.get("lineHeight"))

  const handleChange = (event, newValue) => {
    activeObject.set("lineHeight", newValue);
    setLineHeight(newValue);
    canvas.value.renderAll();
  };

  return ( <>
  {/* center contents of toolbar */}
    <Toolbar
      style={{
        paddingBottom:'10px'
      }}
    >
    <Paper style={{
      height: '100px',
      overflow: 'auto',
      width: '100%'}}>
      <Typography
       align="center"
       gutterBottom>Line Height: {lineHeight}</Typography>
      <Slider
        min={0}
        step={.1}
        max={2}
        color="secondary"
        size="small"
        defaultValue={lineHeight}
        aria-label="Small"
        valueLabelDisplay="auto"
        onChange={handleChange}
      />
      </Paper>
    </Toolbar>
    </>
   );
}

export default LineHeightToolbar;