// MainContainer.jsx
import React, { useEffect, useState, useCallback } from 'react';
// Components
import { Button } from '@/components/Button';
import MyTv from '@/pages/Maker/components/MyTv/MyTv';
import Header from './components/Header';
import PreviewPane from './components/PreviewPane';
import LayersPanel from './components/LayersPanel';
import OptionsList from './components/OptionsList';
import SwatchOptions from './components/SwatchOptions';
import SaveConfigButton from './components/SaveConfigButton';
import Modal from './components/Modal';
import Slider from './components/Slider';
import Panel from './components/Panel';
// Recoil
import { useRecoilState } from 'recoil';
// Recoil atoms
import canvasAtom from '@/atoms/canvas-atom';
// Hooks
import { useSkuConfig } from '@/hooks/useSkuConfig';
import { useSvgFile } from '@/hooks';
import { useSerializedFabricCanvasJSON } from '@/hooks';
// Signals
import { signal } from '@preact/signals-react';
import {sku} from '@/signals/sku';

// TESTING shadcn
import { toast } from "sonner"
import { Button as ShadButton } from '@/components/ui/button';
// Config Maker components
import FileNames from './components/FileNames';
import IsFileLoaded from './components/IsFileLoaded/IsFileLoaded';
import SvgFileName from './components/SvgFileName';
import FabricJSONFileName from './components/FabricJSONFileName';
import SkuInput from './components/Header/SkuInput';
import CloneConfig from './components/CloneConfig';
import Version from './components/Version';
import SaveFabricJSONButton from './components/SaveFabricJSONButton';
import PrintDimensions from './components/PrintDimensions';
import Artboard from './components/Artboard';


function ConfigMaker() {
  console.log('render ConfigMaker', Date.now());
  const [canvas, setCanvas] = useRecoilState(canvasAtom);

  // const [sku, setSku] = useState(''); // Initialize the SKU state
  const [showSvgModal, setShowSvgModal] = useState(false);
  const [showJsonModal, setShowJsonModal] = useState(false);
  const [svgFileName, setSvgFileName] = useState('');
  const [fabricJSONFileName, setFabricJSONFileName] = useState(''); // TODO
  const [configFileName, setConfigFileName] = useState('');
  const [fileType, setFileType] = useState('svg'); // Add a state for the file type
  const [svgContent, setSvgContent] = useState('');
  const [fabricJSON, setFabricJSON] = useState(null); // TODO
  const [layerIds, setLayerIds] = useState([]);
  // const [config, setConfig] = useState({}); // Add a state for the configuration
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [options, setOptions] = useState([]);
  const version = signal('1.1.0')
  const [colorOptions, setColorOptions] = useState([]);
  const [textOptions, setTextOptions] = useState([]);
  const [photoOptions, setPhotoOptions] = useState([]);
  const [swatchOptions, setSwatchOptions] = useState([]);
  const [imageMaps, setImageMaps] = useState([]);
  const [clipPath, setClipPath] = useState("");
  const [artboard, setArtboard] = useState("");
  const [printDimensions, setPrintDimensions] = useState({});

  const formRef = React.useRef(null);

  console.log('configMaker useSkuConfig')
  const { config, isLoading: isConfigLoading, isError } = useSkuConfig();
  console.log('configMaker useSVGFile')
  const { data: svgData, error: svgFileError} = useSvgFile(config?.svg ? true : false);
  console.log('configMaker config', config)
  const { data: fabricData } =  useSerializedFabricCanvasJSON(config?.file ? true : false);

  // console.log(isConfigLoading, "do I reach here?")

  // once we pass by this, then we can use the config
  // whether or not it's null because this is a config maker
  // if (isConfigLoading) {
  //   return <div>Loading configuration...</div>;
  // }


  useEffect(() => {
    console.log('fabricJSON:', fabricData)  
    if(fabricData) setFabricJSON(fabricData);
  }, [fabricData]);

  useEffect(() => {
    console.log('svgContent:', svgData)
    if(svgData) setSvgContent(svgData);
  }, [svgData]);

  useEffect(() => {
      setColorOptions(config?.color_options || []);
      setTextOptions(config?.text_options || []);
      setPhotoOptions(config?.photo_options || []);

      setSwatchOptions(config?.swatch_options || []);

      setFileType(config?.default_file_type || 'svg'); // Set the file type based on the config

      // set the file names
      setSvgFileName(config?.svg || '');
      setFabricJSONFileName(config?.file || '');
    
      setImageMaps(config?.image_maps || []);
      setClipPath(config?.clipPath || '');
      setArtboard(config?.artboard || '');
      setPrintDimensions(config?.printDimensions || {});


      // 
  }, [config]);


  const toggleSvgModal = () => setShowSvgModal(!showSvgModal);
  const toggleJsonModal = (e) => {
    e.preventDefault();
    setShowJsonModal(!showJsonModal)
  };


  // Function to save SVG to file
  const saveSvgToFile = (svgContent) => {
    fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/save-updated-svg`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        svgFileName,
        svgContent,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('SVG saved successfully:', data);
      })
      .catch(error => {
        console.error('Error saving SVG:', error);
      });
  };


  function updateSvgContentAndParseIds(newSvgContent) {
    setSvgContent(newSvgContent);
    parseSvgForIds(newSvgContent);
  }


  // Handlers for creating options
  const createColorOption = () => {
    setColorOptions(prevOptions => [...prevOptions, { name: '', members: [] }]);
  };


  const createTextOption = () => {
    setTextOptions(prevOptions => [...prevOptions, { name: '', members: [] }]);
  };

  const createPhotoOption = () => {
    setPhotoOptions(prevOptions => [...prevOptions, { name: '', members: [] }]);
  };



  const parseSvgForIds = (svg) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
    const elementsWithId = svgDoc.querySelectorAll('[id]');
    const layerData = Array.from(elementsWithId)
      .reverse()
      .filter(el => el.tagName !== "svg")
      .map((el) => {
      const id = el.id;
      const reservedWords = ['mask', 'edge', 'guide', 'orn', 'artwork', 'art', 'design'];
      let type = 'color'; // default type
      const reservedWordRegex = new RegExp(`\\b(${reservedWords.join('|')})\\b`, 'i');
      if (id.match(reservedWordRegex)) {
        type = 'reserved';
      } else if (id.includes('photo')) {
        type = 'photo';
      } else if (el.tagName === 'text') {
        type = 'text';
      }
      return { id, name: id, type }; // assuming the name is the same as the id
    });
    setLayerIds(layerData); // Update to set the structured layer data
  };

  useEffect(() => {
    if (svgContent) {
      parseSvgForIds(svgContent);
      // saveSvgToFile(svgContent); // Save SVG file whenever svgContent is updated
    }
  }, [svgContent]);


const handleNameChange = useCallback((optionType, optionIndex, newName) => {
  // const handleNameChange = (optionType, optionIndex, newName) => {
    switch (optionType) {
      case 'color':
        setColorOptions(prevOptions => {
          if (!prevOptions[optionIndex]) {
            console.error('Color option at index ' + optionIndex + ' does not exist.');
            return prevOptions;
          }
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].name = newName;
          return updatedOptions;
        });
        break;
      case 'text':
        setTextOptions(prevOptions => {
          if (!prevOptions[optionIndex]) {
            console.error('Text option at index ' + optionIndex + ' does not exist.');
            return prevOptions;
          }
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].name = newName;
          return updatedOptions;
        });
        break;
      case 'photo':
        setPhotoOptions(prevOptions => {
          if (!prevOptions[optionIndex]) {
            console.error('Photo option at index ' + optionIndex + ' does not exist.');
            return prevOptions;
          }
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].name = newName;
          return updatedOptions;
        });
        break;
      default:
        console.error('Invalid option type');
    }
  }, []); // Dependency array is empty, meaning this function is created once


  const handleMemberChange = (optionType, optionIndex, newMembers) => {
    switch (optionType) {
      case 'color':
        setColorOptions(prevOptions => {
          if (!prevOptions[optionIndex]) {
            console.error('Color option at index ' + optionIndex + ' does not exist.');
            return prevOptions;
          }
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].members = [...updatedOptions[optionIndex].members, ...newMembers];
          return updatedOptions;
        });
        break;
      case 'text':
        setTextOptions(prevOptions => {
          if (!prevOptions[optionIndex]) {
            console.error('Text option at index ' + optionIndex + ' does not exist.');
            return prevOptions;
          }
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].members = [...updatedOptions[optionIndex].members, ...newMembers];
          return updatedOptions;
        });
        break;
      case 'photo':
        setPhotoOptions(prevOptions => {
          if (!prevOptions[optionIndex]) {
            console.error('Photo option at index ' + optionIndex + ' does not exist.');
            return prevOptions;
          }
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].members = [...updatedOptions[optionIndex].members, ...newMembers];
          return updatedOptions;
        });
        break;
      default:
        console.error('Invalid option type');
    }
  };



  const updateSwatchOptions = useCallback((options) => {
    setSwatchOptions(options);
  }, []); // Dependency array is empty, meaning this function is created once


  const handleDeleteMember = (optionType, optionIndex, memberIndex) => {
    switch (optionType) {
      case 'color':
        setColorOptions(prevOptions => {
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].members = updatedOptions[optionIndex].members.filter((_, idx) => idx !== memberIndex);
          return updatedOptions;
        });
        break;
      case 'text':
        setTextOptions(prevOptions => {
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].members = updatedOptions[optionIndex].members.filter((_, idx) => idx !== memberIndex);
          return updatedOptions;
        });
        break;
      case 'photo':
        setPhotoOptions(prevOptions => {
          const updatedOptions = [...prevOptions];
          updatedOptions[optionIndex].members = updatedOptions[optionIndex].members.filter((_, idx) => idx !== memberIndex);
          return updatedOptions;
        });
        break;
      default:
        console.error('Invalid option type');
    }
  };


  // Function to handle the deletion of an option
  const handleDeleteOption = (optionType, optionIndex) => {
    // Determine which options state to update based on the optionType
    switch (optionType) {
      case 'color':
        setColorOptions((prevOptions) => prevOptions.filter((_, index) => index !== optionIndex));
        break;
      case 'text':
        setTextOptions((prevOptions) => prevOptions.filter((_, index) => index !== optionIndex));
        break;
      case 'photo':
        setPhotoOptions((prevOptions) => prevOptions.filter((_, index) => index !== optionIndex));
        break;
      // Add additional cases for other option types if necessary
      default:
        console.error('Invalid option type');
    }
  };



  const onDragEnd = (result) => {
    // Logic to reorder layers based on drag result
  };

  const addLayerOption = (layerType) => {
    // Logic to add a new layer option based on layerType ('color', 'text', 'photo')
  };


  // Methods to manipulate state would go here. For example:
  // addLayer, removeLayer, updateLayerOption, saveConfiguration, etc.

  const onEditOption = (index) => {
    // Logic for editing an option
  };

  const onRemoveOption = (index) => {
    // Logic for removing an option
    setOptions(currentOptions => currentOptions.filter((_, i) => i !== index));
  };


  function saveConfiguration(e) {
    e.preventDefault();

    const formData = new FormData(e.target);

    const svg = formData.get("svgFileName");
    const file = formData.get("fabricJSONFileName");
    console.log("the mf file: ", file)
  
    const configuration = {
      version : version.value,
      name: "", // Modify as needed
      file: file,
      svg: formData.get("svgFileName"),
      default_file_type: fileType,
      color_options: colorOptions,
      text_options: textOptions,
      photo_options: photoOptions,
      templates: [], // Assuming you manage templates
      swatch_options: swatchOptions,
      image_maps: imageMaps,
      clipPath,
      artboard,
      printDimensions
    };

    console.log('Saving configuration:', configuration);

    // setIsSaving(true);

    fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/admin-save-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sku: sku.value,
        configData: configuration,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parses the JSON only if the response is okay
      })
      .then(data => {
        console.log('Configuration saved successfully:', data);
        toast.success('Configuration saved successfully')
        // toast("Configuration saved successfully")
        // Handle your success scenario
      })
      .catch(error => {
        console.error('Error saving configuration:', error);
        // Handle your error scenario
      })
      .finally(() => {
        // setIsSaving(false);
      });
  }

  const clone = (e) => {
    e.preventDefault();
    const formData = new FormData(document.getElementsByTagName('form')[0]);

    const sku = formData.get("cloneSku");
    console.log('Cloning configuration for SKU:', sku);
  
    const configuration = {
      name: "", // Modify as needed
      file: formData.get("fabricJSONFileName"),
      svg: formData.get("svgFileName"),
      default_file_type: fileType,
      color_options: colorOptions,
      text_options: textOptions,
      photo_options: photoOptions,
      templates: [], // Assuming you manage templates
      swatch_options: swatchOptions
    };

    fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/admin-save-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sku:sku.value,
        configData: configuration,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parses the JSON only if the response is okay
      })
      .then(data => {
        console.log('Configuration saved successfully:', data);
        toast.success('Configuration saved successfully')
        // toast("Configuration saved successfully")
        // Handle your success scenario
      })
      .catch(error => {
        console.error('Error saving configuration:', error);
        // Handle your error scenario
      })
      .finally(() => {
        // setIsSaving(false);
      });
  }



  function handleOptimizeAndSave(svgFileName, svgContent, updateSvgContentAndParseIds) {
    // Start the POST request to the server-side Netlify function
    fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/save-svg-file`, {
      method: 'POST',
      body: JSON.stringify({ svgFileName, svgContent }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Optimized SVG:', data);
        // Check if the server returns the optimized SVG content
        if (data.optimizedSvgContent) {
          // Update the SVG content in the client state
          setSvgContent(data.optimizedSvgContent);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  useEffect(() => {
    document.body.style.overflow = 'unset';
  }
    , []);


  return (
    <div className="main-container tw-bg-slate-500">
      <form ref={formRef} onSubmit={saveConfiguration}>
        <h1>Sku: {sku} </h1>
      <Header
        sku={sku.value}
        svgFileName={svgFileName}
        setSvgFileName={setSvgFileName}
        fabricJSONFileName={fabricJSONFileName}
        setFabricJSONFileName={setFabricJSONFileName} 
        setSvgContent={setSvgContent}
        // loadConfiguration={loadConfiguration} DELETE:
        svgLoaded={svgContent}
        configLoaded={config}
        fabricJSONLoaded={fabricJSON}
        // configLoaded={Object.keys(config).length != 0}
      >
        <Slider
          fileType={fileType} // Pass the file type to the Header
          setFileType={setFileType}
        />
        <Button variant="navy" onClick={() => handleOptimizeAndSave(svgFileName, svgContent)}>
          Optimize SVG
        </Button>
        <Button onClick={toggleSvgModal}>View SVG</Button>
        {showSvgModal && (
          <Modal
            content={svgContent}
            language={"xml"}
            onClose={toggleSvgModal}
          />
        )}
        <Button onClick={(e) => toggleJsonModal(e)}>View JSON</Button>
        {showJsonModal && (
          <Modal
            content={JSON.stringify(config, null, 2)}
            language={"json"}
            onClose={toggleJsonModal}
          />
        )}
        <Button onClick={(e) => {
          e.preventDefault();
          console.log(canvas);
        }}>View Fabric Canvas</Button>
        <SaveConfigButton
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          saveStatus={saveStatus}
          setSaveStatus={setSaveStatus}
          // onClick={saveConfiguration}
        />
        <SaveFabricJSONButton filename={config.file} formRef={formRef} />
        <CloneConfig clone={clone}/>
      </Header>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <SkuInput sku={sku} />
        <SvgFileName svgFileName={svgFileName} />
        <FabricJSONFileName fabricJSONFileName={fabricJSONFileName} />
        <Version version={version} />
      </div>
      <div>
          <IsFileLoaded isLoaded={config} label="CONFIG FILE LOADED" />
          <IsFileLoaded isLoaded={svgContent} label="SVG FILE LOADED" />
          <IsFileLoaded isLoaded={fabricJSON} label="FABRIC JSON FILE LOADED" />
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-p-4">
        <div className="tw-w-full md:tw-w-1/2 tw-p-2">
          {/* <div style={{ display: fileType === 'json' ? 'block' : 'none' }}>
            <MyTv />
          </div>
          <div style={{ display: fileType !== 'json' && svgContent ? 'block' : 'none' }}>
            {svgContent && <PreviewPane svgContent={svgContent} />}
          </div> */}
          {fileType === 'json' ? <MyTv /> : svgContent && <PreviewPane svgContent={svgContent} />}
        </div>
        <div className="tw-w-full md:tw-w-1/2 tw-p-2">
          <Panel title="Layers">
            <LayersPanel
              layers={layerIds} // assuming layerIds is now an array of objects
              onDragEnd={onDragEnd}
              addLayerOption={addLayerOption}
              updateSvgContentAndParseIds={updateSvgContentAndParseIds}
              svgFileName={svgFileName} // Pass the file name to the LayersPanel
              svgContent={svgContent} // Pass the svg content to the LayersPanel
              setColorOptions={setColorOptions}
              setTextOptions={setTextOptions}
              setPhotoOptions={setPhotoOptions}
              saveSvgToFile={saveSvgToFile}
            />
          </Panel>

          <Panel title="Options">
            <div className="tw-p-4">
              <button onClick={createColorOption} className="tw-bg-blue-500 tw-text-white tw-py-2 tw-px-4 tw-mr-2">Create Color Option</button>
              <button onClick={createTextOption} className="tw-bg-green-500 tw-text-white tw-py-2 tw-px-4 tw-mr-2">Create Text Option</button>
              <button onClick={createPhotoOption} className="tw-bg-red-500 tw-text-white tw-py-2 tw-px-4">Create Photo Option</button>
              {/* Render the options panels here */}
            </div>
          </Panel>
          <SwatchOptions
            updateSwatchOptions={updateSwatchOptions}
            initialSwatchOptions={config?.swatch_options}
          />

      <Panel title="Print Dimensions">
        <PrintDimensions 
          printDimensions={printDimensions}
          setPrintDimensions={setPrintDimensions}
          />
      </Panel>
      <Panel title="Artboard">
        <Artboard 
          artboard={artboard}
          setArtboard={setArtboard}
        />
      </Panel>
        </div>
      </div>

      <OptionsList
        // options={options}
        colorOptions={colorOptions}
        setColorOptions={setColorOptions}
        textOptions={textOptions}
        setTextOptions={setTextOptions}
        photoOptions={photoOptions}
        setPhotoOptions={setPhotoOptions}
        onEditOption={onEditOption}
        onRemoveOption={onRemoveOption}
        handleMemberChange={handleMemberChange}
        handleNameChange={handleNameChange}
        handleDeleteMember={handleDeleteMember}
        handleDeleteOption={handleDeleteOption}
      />
      </form>
    </div>
  );
}

export default ConfigMaker;