import { Button } from "@/components/Button";

const SaveConfigButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
    >Save Config</Button>
  )
}

export default SaveConfigButton;