import React from 'react';

const LineHeightIcon = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.32782 5.94055L5.35034 9.91803L6.67617 11.2439L8.43724 9.48278V21.7364L6.67617 19.9753L5.35034 21.3012L9.32782 25.2787L13.3053 21.3012L11.9795 19.9753L10.3122 21.6425V9.57663L11.9795 11.2439L13.3053 9.91803L9.32782 5.94055ZM25.3124 7.2664H14.9999V9.1414H25.3124V7.2664ZM25.3124 22.2664H14.9999V24.1414H25.3124V22.2664ZM14.9999 14.7664H25.3124V16.6414H14.9999V14.7664Z" fill="white"/>
</svg>

  );
};

export default LineHeightIcon;
