import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LayerObject from './LayerObject';

const LayersPanel = ({ layers, svgFileName, svgContent, updateSvgContentAndParseIds, setColorOptions, setTextOptions, setPhotoOptions, saveSvgToFile }) => {
  
  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return; // Drag ended outside the droppable area
    }

    const newLayers = Array.from(layers);
    const [reorderedItem] = newLayers.splice(result.source.index, 1);
    newLayers.splice(result.destination.index, 0, reorderedItem);

    // Extract the layer IDs in the new order
    const layerIds = newLayers.map(layer => layer.id);

    // Send the new order to Netlify function to update the SVG content
    try {
      const response = await fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/admin-sort-svg-layers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ svgContent, layerIds }),
      });

      const data = await response.json();
      if (data.updatedSvgContent) {
        // Update the SVG content in the client state
        updateSvgContentAndParseIds(data.updatedSvgContent);
      }
    } catch (error) {
      console.error('Error updating layer order:', error);
    }
  };

  // The function to add a new option for a layer
  const addOptionForLayer = (e, layer) => {
    e.preventDefault();
    const newOption = {
      name: layer.name,
      members: [layer.name]
    };
    
    // Determine the type of layer and add the newOption to the correct state
    switch (layer.type) {
      case 'color':
        setColorOptions(prevOptions => [...prevOptions, newOption]);
        break;
      case 'text':
        setTextOptions(prevOptions => [...prevOptions, newOption]);
        break;
      case 'photo':
        setPhotoOptions(prevOptions => [...prevOptions, newOption]);
        break;
      default:
        console.error('Unknown layer type');
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="layersDroppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {layers.map((layer, index) => (
              <Draggable key={layer.id} draggableId={layer.id} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <LayerObject 
                      svgFileName={svgFileName}
                      svgContent={svgContent}
                      layer={layer}
                      updateSvgContentAndParseIds={updateSvgContentAndParseIds}
                      addOption={(e) => addOptionForLayer(e, layer)}
                      saveSvgToFile={saveSvgToFile}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default LayersPanel;
