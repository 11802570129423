import axios from 'axios';
import { useRecoilState } from "recoil"
import toolbarAtom from "@/atoms/toolbar-atom"
import selectionTypeAtom from "@/atoms/selection-type-atom"
import canvasAtom from "@/atoms/canvas-atom"
import { useState, useEffect } from "react"
import { Button, Drawer, Toolbar, Typography } from "@mui/material"
// import { data } from '@/utils/skuObjectLookup'
// import skuObjectLookup from "../../utils/skuObjectLookup"
import { configSelector } from "@/atoms/config-atom";
import { Stack } from "@mui/system"
import { useRecoilValue } from "recoil"
import { templatesSelector } from "../../../../atoms/config-atom"
import ConfigForm from "../ConfigForm/ConfigForm"
import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import ConfigMaker from '@/pages/admin/ConfigMaker';

// signals
import { sku } from '@/signals/sku';


const Debug = (props) => {
  const { visible, setDebug } = props
  const [toolbar, setToolbar] = useRecoilState(toolbarAtom)
  const [canvas] = useRecoilState(canvasAtom)
  const templates = useRecoilValue(templatesSelector)

  const config = useRecoilValue(configSelector); // Using the selector here

  // Local state to hold the SKU input
  const [inputSku, setInputSku] = useState('');

  // Function to handle SKU updates
  const handleUpdateSku = () => {
    sku.value = inputSku; // Update the global SKU signal
  };

  // Update inputSku whenever sku changes
  useEffect(() => {
    setInputSku(sku);
  }, [sku]);




  return <>
    <Drawer
       sx={{
        width: '100vw', // Full width of the viewport
        '& .MuiDrawer-paper': {
          width: '100vw', // Full width of the viewport
          boxSizing: 'border-box', // Include padding and borders in the element's total width and height
        },
      }}
      anchor="left"
      open={props.visible}
      onClose={() => setDebug(false)}
    >
      <ConfigMaker />
    {/* <Box 
      sx={{ flexGrow: 1, backgroundColor: 'secondary.main' }}>
        <label className='tw-text-white'>Sku:</label>
        <input type="text"
          value={inputSku}
          onChange={(e) => setInputSku(e.target.value)} />
        <Button variant="contained" onClick={() => setSku(inputSku)}>Update SKU</Button>
        <Stack className="tw-text-white">
          <span>Mode: {import.meta.env.MODE}</span>
          <span>Name: {config.name}</span>
          <span>Templates: {templates ? templates.length : 0}</span>
          <span>Toolbar: {toolbar}</span>
        </Stack>
        </Box> */}
      {/* <ConfigForm /> */}
      {/* <ConfigMaker /> */}
    </Drawer>
  </>
}

export default Debug