import React, { useState } from 'react';
import { Button } from "@/components/Button";
import styles from "./SkuInput.module.css";

const SkuInput = ({ sku }) => {
  const [localSku, setLocalSku] = useState(sku);

  return (
      <div className={styles.skuInput}>
        <input 
          type="text"
          defaultValue={sku.value}
          onChange={(e) => setLocalSku(e.target.value)} 
          placeholder='Enter a SKU'
        />
        <Button variant="secondary" onClick={(e)=>{
          e.preventDefault();
          sku.value = localSku;
          console.log('localSku', localSku);
          console.log("sku.value", sku.value)
          // setSku(localSku)
          }}>Load</Button>
      </div>
  );
}

export default SkuInput;
