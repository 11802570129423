import React, { useRef, forwardRef } from 'react';
import { useRecoilValue } from 'recoil';
import AppContainer from '@/components-app/AppContainer';
import MyTv from '@/pages/Maker/components/MyTv';
import Footer from '@/pages/Maker/components/Footer';
import {ShopifyHeader, ShipStationHeader, AdminHeader} from '@/pages/Maker/components/Headers';
import appModeAtom from '@/atoms/app-mode-atom';

const Maker = forwardRef((props, ref) => {
  const appMode = useRecoilValue(appModeAtom);
  const containerRef = useRef();
  const headerRef = useRef();
  const tvRef = useRef();
  const footerRef = useRef();

  let HeaderComponent;

  switch (appMode) {
    case 'ADMIN':
      HeaderComponent = AdminHeader;
      break;
    case 'SHIPSTATION':
      HeaderComponent = ShipStationHeader;
      break;
    case 'SHOPIFY':
    default:
      HeaderComponent = ShopifyHeader;
      break;
  }

  return (
    <AppContainer ref={containerRef}>
      <HeaderComponent ref={headerRef} />
      {/* { import.meta.env.MODE==='admin' ? 
        <AdminHeader ref={headerRef}/> : 
        <Header ref={headerRef}/> } */}
      <MyTv ref={tvRef} />
      <Footer ref={footerRef} />
    </AppContainer>
  );
});

export default Maker;
