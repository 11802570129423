import Button from './Button';

const OrbButton = ({ variant, children, ...rest }) => {
  // Concatenate "orb" with any additional variant passed in
  const combinedVariant = `orb${variant ? ` ${variant}` : ''}`;

  return (
    <Button variant={combinedVariant} {...rest}>
      {children}
    </Button>
  );
};

export default OrbButton;
