const PrintDimensions = ({ printDimensions, setPrintDimensions }) => {

  console.log(JSON.stringify(printDimensions))
  let defaultValue ="" 
  switch (JSON.stringify(printDimensions)) {
    case `{"width":7200,"height":4800}`:
      defaultValue="24x16 - matte"
      break;
    case `{"width":8400,"height":6000}`:
      defaultValue="24x16 - canvas"
      break;
    case `{"width":9000,"height":6000}`:
      defaultValue="30x20 - matte"
      break;
    case `{"width":10200,"height":7200}`:
      defaultValue="30x20 - canvas"
      break;
    case `{"width":10800,"height":7200}`:
      defaultValue="36x24 - matte"
      break;
    case `{"width":12000,"height":8400}`:
      defaultValue="36x24 - canvas"
      break;
    default:
      defaultValue="24x16 - matte"
      break;
  }

    const handleOnChange = (e) => {
      const PRINT_RESOLUTION = 300;
      switch (e.target.value) {
        case "24x16 - matte":
          setPrintDimensions({ width: (24)*PRINT_RESOLUTION, height: (16)*PRINT_RESOLUTION })
          break;
        case "24x16 - canvas":
          setPrintDimensions({ width: (24+4)*PRINT_RESOLUTION, height: (16+4)*PRINT_RESOLUTION })
          break;
        case "30x20 - matte":
          setPrintDimensions({ width: (30)*PRINT_RESOLUTION, height: (20)*PRINT_RESOLUTION })
          break;
        case "30x20 - canvas":
          setPrintDimensions({ width: (30+4)*PRINT_RESOLUTION, height: (20+4)*PRINT_RESOLUTION })
          break;
        case "36x24 - matte":
          setPrintDimensions({ width: (36)*PRINT_RESOLUTION, height: (24)*PRINT_RESOLUTION })
          break;
        case "36x24 - canvas":
          setPrintDimensions({ width: (36+4)*PRINT_RESOLUTION, height: (24+4)*PRINT_RESOLUTION })
          break;
      
        default:
          break;
      }

    }

    return (
        <div className="tw-p-4">
            <select name="print_dimensions"
              className="tw-w-full tw-p-4"
              onChange={handleOnChange}
              value={defaultValue}
            >
              <option
                value="24x16 - matte">24"x16" matte</option>
              <option value="24x16 - canvas">24"x16" canvas</option>
              <option value="30x20 - matte">30"x20" matte</option>
              <option value="30x20 - canvas">30"x20" canvas</option>
              <option value="36x24 - matte">36"x24" matte</option>
              <option value="36x24 - canvas">36"x24" canvas</option>
            </select>
        </div>
    );
}

export default PrintDimensions;