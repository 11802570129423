import React, { useEffect } from 'react';
// import { QueryClient, QueryClientProvider } from 'react-query';
// Themeing and CSS
import './App.css';
// components
import { ContextProviders } from '@/components/ContextProviders';
import { AppContent } from './components/AppContent';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
// Atoms
import appModeAtom from '@/atoms/app-mode-atom';

// Function to set the vh variable, the reason for this is that mobile browsers
// have a bug where the vh unit is calculated incorrectly, this function will
// set the --vh variable in the document root to the correct value
const setVHVariable = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

function App({initialMode}) {

  useEffect(() => {
    // Initial set
    setVHVariable();
    // Update the variable whenever the window is resized
    window.addEventListener('resize', setVHVariable);
  
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', setVHVariable);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const setAppMode = useSetRecoilState(appModeAtom);
  setAppMode(initialMode);

  return (
    <>
    <ContextProviders>
      <AppContent />
    </ContextProviders>
    </>
  );
}

export default App;
