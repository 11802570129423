import React, { useState } from 'react';
import { filters } from 'fabric';
import { useRecoilState } from 'recoil';
import { Toolbar } from '@mui/material';
import { CameraIcon } from '@/components/Icons';
import { Button, OrbButton } from '@/components/Button';
import heic2any from "heic2any";
import { createThumbnail, saveImage, saveThumbnail } from '@/dexie/dbHelpers';
// symbols
import { canvas } from '@/signals/canvas';



const ImageControlUiToolbar = ({ onUploadComplete, setGalleryVisible, galleryVisible }) => {
  const [isLoading, setIsLoading] = useState(false);


  const handleFileInput = async (e) => {
    try {
      setIsLoading(true);

      // Selected file is the file the user is trying to upload
      const selectedFile = e.target.files[0];

      // Image guards
      guardAgainstNonImageFiles(selectedFile);
      guardAgainstLargeFiles(selectedFile, 10);
      // If the file is a HEIC image, convert it to JPEG
      // otherwise, just return the file
      const file = await convertHEICImagesToJPEG(selectedFile);

      // Create thumbnail and full size Image and save them to IndexedDB
      await saveImage(file.name, file);

      setIsLoading(false);

      if (typeof onUploadComplete === 'function') {
        onUploadComplete(); // Call this function after successful upload
      }
    } catch (error) {
      setIsLoading(false);
      // Handle the error here
      // For example, show an alert or set an error message in your state
      alert(`Failed to upload file: ${error.message}`);
    }
  };

  const handleEnglargeImage = () => {
    // This is to deal with group or direct selection
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    // find the object that's clip path === activeObject)
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID
    });
    img.scaleX *= 1.1;
    img.scaleY *= 1.1;
    canvas.value.renderAll();
  };


  const handleShrinkImage = () => {
    // This is to deal with group or direct selection
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });
    img.scaleX *= 0.9;
    img.scaleY *= 0.9;
    canvas.value.renderAll();
  };

  const handleRotateImage = () => {
    // This is to deal with group or direct selection
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });
    img.set({ originX: 'center', originY: 'center' });  // Set the origin to the center
    img.angle += 90;  // Rotate by 10 degrees
    canvas.value.renderAll();
  };

  const handleGreyscale = () => {
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });

    const GrayscaleFilter = filters.Grayscale;
    const existingFilterIndex = img.filters.findIndex(filter => filter instanceof GrayscaleFilter);

    if (existingFilterIndex === -1) {
      // Filter is not applied yet, so add it
      img.filters.push(new GrayscaleFilter());
    } else {
      // Filter is already applied, so remove it
      img.filters.splice(existingFilterIndex, 1);
    }

    img.applyFilters();
    canvas.value.renderAll();
  };

  const handleSepia = () => {
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });

    const SepiaFilter = filters.Sepia;
    const existingFilterIndex = img.filters.findIndex(filter => filter instanceof SepiaFilter);

    if (existingFilterIndex === -1) {
      // Filter is not applied yet, so add it
      img.filters.push(new SepiaFilter());
    } else {
      // Filter is already applied, so remove it
      img.filters.splice(existingFilterIndex, 1);
    }

    img.applyFilters();
    canvas.value.renderAll();
  };

  const handleMoveImage = (direction) => {
    // This is to deal with group or direct selection
    const activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    const img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });

    const moveBy = 10; // Move by 10 pixels

    switch (direction) {
      case 'up':
        img.top -= moveBy;
        break;
      case 'down':
        img.top += moveBy;
        break;
      case 'left':
        img.left -= moveBy;
        break;
      case 'right':
        img.left += moveBy;
        break;
      default:
        break;
    }
    canvas.value.renderAll();
  };

  return (<>
    <Toolbar
      className='tw-gap-1 tw-flex tw-flex-row tw-justify-center tw-items-center'
    >
      <OrbButton
        variant="navy"
        onClick={() => {
          setGalleryVisible(!galleryVisible);
        }}
        icon={<CameraIcon />}
      />
      {/* Hidden file input */}
      <input type="file"
        id="image-btn"
        hidden
        onChange={handleFileInput} />

      <OrbButton
        className="tw-text-white"
        variant="navy"
        onClick={handleEnglargeImage}
      >+</OrbButton>
      <OrbButton variant="navy"
        onClick={handleShrinkImage}
      >-</OrbButton>

      <Button
        variant="navy orb right"
        onClick={() => handleMoveImage('right')}
      >&#x2794;</Button>
      <Button
        variant="navy orb left"
        onClick={() => handleMoveImage('left')}
      >&#x2794;</Button>
      <Button
        variant="navy orb up"
        onClick={() => handleMoveImage('up')}
      >&#x2794;</Button>
      <Button
        variant="navy orb down"
        onClick={() => handleMoveImage('down')}
      >&#x2794;</Button>
    </Toolbar>
    <Toolbar
      className='tw-gap-2 tw-flex tw-flex-row tw-justify-center tw-items-center'
    >
      <Button
        variant="navy orb"
        onClick={() => handleRotateImage()}
      >&#8634;</Button>

      <Button
        variant="navy"
        onClick={handleGreyscale}
      >
        Grayscale
      </Button>
      {/* TODO: There was an issue with deserializing images with SEPIA filter. This needs to be fixed
          before we can enable this feature */}
      {/* <Button
        variant="navy"
        onClick={handleSepia}
      >
        Sepia
      </Button> */}
    </Toolbar>
  </>
  );
};

export default ImageControlUiToolbar;


function guardAgainstNonImageFiles(file) {
  if (!file.type.startsWith('image/')) {
    throw new Error('File is not an accepted image format. Please contact help@peachwik.com for assistance.');
  }
}

function guardAgainstLargeFiles(file, maxSizeInMB) {
  if (file.size > maxSizeInMB * 1024 * 1024) {
    throw new Error('File is too large. Please compress it or contact help@peachwik.com for assistance');
  }
}

async function convertHEICImagesToJPEG(file) {
  if (file.type !== 'image/heic') return file;
  if (file.type === 'image/heic') {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1
      });
      return new File([convertedBlob], file.name.replace(/\..+$/, '.jpeg'), { type: 'image/jpeg' });
    } catch (error) {
      throw new Error('Error converting HEIC image. Please contact help@peachwik.com for assistance');
    }
  }
}