import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { RecoilRoot } from 'recoil';

// Keep a reference to the root
let root = null;

// In App component
window.addReactApp = (initialMode) => {
  console.log('Adding React app', initialMode);
  if (initialMode === 'SHOPIFY') return;
  // If root is already defined, unmount the existing React root
  if (root) {
    root.unmount();
  }

  // Function to remove the React app
  window.removeReactApp = () => {
    if (root) {
      root.unmount();
      root = null; // Reset the root reference
    }
  }
  root = ReactDOM.createRoot(document.getElementById('peachwik-root'));
  root.render(
    <RecoilRoot>
      <App initialMode={initialMode} />
    </RecoilRoot>
  );
}

// In your main entry file
let initialMode = 'ADMIN';
if (window.location.hostname === 'localhost') initialMode = 'ADMIN'; // udpate this to test other modes
if (window.location.hostname === 'create.peachwik.com') initialMode = 'SHIPSTATION';
if (window.location.hostname === 'stellar-florentine-4bf375.netlify.app') initialMode = 'SHIPSTATION';
if (window.location.hostname === 'production.peachwik.com') initialMode = 'SHIPSTATION';
if (window.location.hostname === 'peachwik.com') initialMode = 'SHOPIFY';
console.log('initialMode: ', initialMode);
console.log('window.location.hostname: ', window.location.hostname);

window.addReactApp(initialMode);