import styles from "./Button.module.css";

const Button = ({ onClick, variant = "primary", children, icon }) => {
  // Split the variant string into an array and map each variant to a style
  const buttonClasses = variant.split(" ").map(variantName => styles[variantName]);

  // Join the styles array into a single string
  const buttonClass = [styles.button, ...buttonClasses].join(" ");

  return (
    <button
      className={buttonClass}
      onClick={onClick}
    >
      {children}
      {icon}
    </button>
  );
}

export default Button;
