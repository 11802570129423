import styles from './HorizontalScroll.module.css'; 

import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from 'react-spring';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeft from '@mui/icons-material/ArrowCircleLeft';

const HorizontalScroll = ({ children }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const animationProps = useSpring({
    from: { transform: 'translate3d(0px, 0, 0)' },
    to: { transform: 'translate3d(20px, 0, 0)' },
    config: { duration: 1000 },
  });

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  const scrollBy = (offset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: offset, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return ( 
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-gap-2">
        {showLeftArrow && 
        <div className={styles.swipeControl} onClick={() => scrollBy(-100)}>
          <ArrowCircleLeft sx={{ fontSize: 30 }} />
          </div>}
        
        <div ref={scrollRef} className="tw-flex tw-flex-row tw-overflow-x-auto tw-whitespace-nowrap tw-w-full" onScroll={handleScroll}>
          <animated.div style={animationProps}>
            <div className="tw-flex tw-flex-row"> {/* this extra div is to make sure it's contents are horizontal */}
            {children}
            </div>
          </animated.div>
        </div>
        
        {/* {showRightArrow && <div className="arrow right-arrow" onClick={() => scrollBy(100)}><ArrowCircleRightIcon /></div>} */}
        {showRightArrow && 
        <div className={styles.swipeControl} onClick={() => scrollBy(100)}>
          <ArrowCircleRightIcon sx={{ fontSize: 30 }} />
          </div>
          }
      </div>
    </>
  );
};

export default HorizontalScroll;
