import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"

import { cn } from "@/utils"

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "tw-peer tw-inline-flex tw-h-8 tw-w-14 tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-[3px] tw-border-transparent tw-shadow-lg tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-4 focus-visible:tw-ring-primary focus-visible:tw-ring-offset-4 focus-visible:tw-ring-offset-background disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary data-[state=unchecked]:tw-bg-input",
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "tw-pointer-events-none tw-block tw-h-6 tw-w-6 tw-rounded-full tw-bg-background tw-shadow-xl tw-ring-0 tw-transition-transform data-[state=checked]:tw-translate-x-7 data-[state=unchecked]:tw-translate-x-0"
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
