import styles from './Confirm.module.css';
import { Button } from '@/components/Button';
import ButtonGroup from '@/components/ButtonGroup';

const Confirm = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className={styles.confirmWrapper}>
      <div className={styles.confirm}>
        <div className={styles.title}>{title}</div>
        <p className={styles.message}>
          {message}
        </p>
        <hr />
        <div className={styles.confirmButtons}>
          <Button
            onClick={onCancel}>Cancel</Button>
          <Button
            variant="navy"
            onClick={onConfirm}>Confirm</Button>
        </div>
      </div>
    </div>
  );
}

export default Confirm;