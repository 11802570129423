import React from 'react';

// Updated Inline Styles
const styles = {
  overlay: {
    position: 'fixed',     // Covers the whole screen
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Semi-transparent black
    display: 'flex',       // Using flexbox to center content
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000  // Increase the z-index to display it on top of everything else
  },
  loader: {
    border: '16px solid #B2C5C3',
    borderRadius: '50%',
    borderTop: '16px solid #7B9B98',
    width: '120px',
    height: '120px',
    WebkitAnimation: 'spin 2s linear infinite',
    animation: 'spin 2s linear infinite'
  },
  spinKeyframes: `
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `,
  contentWrapper: {
    backgroundColor: 'transparent',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    display: 'flex',       // Added flex
    flexDirection: 'column',  // Stack children vertically
    alignItems: 'center',     // Center children horizontally
    justifyContent: 'center'  // Center children vertically
  }
};

const Processing = ({message}) => {
  return (
    <div style={styles.overlay}>
      <style>{styles.spinKeyframes}</style>
      <div style={styles.contentWrapper}>
      <div style={styles.loader}>&nbsp;</div>
        <h2 className='tw-text-white'>{message}</h2>
      </div>
    </div>
  );
};

export default Processing;
