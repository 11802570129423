import { Button }  from '@/components/ui/button'

const CloneConfig = ({clone}) => {
  return (
  <>
    <input type="text" className='tw-input tw-p-2'
      name="cloneSku"
      placeholder='Enter New SKU'
    />
    <Button
      onClick={(e) =>clone(e)}
    >Clone</Button>
  </>
)}

export default CloneConfig