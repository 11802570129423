// signals
import { canvas } from "@/signals/canvas";
// components
import { NavyButton } from "@/components/Button";
// hooks
import { useSkuConfig } from "@/hooks";
// fabric 
import { Group } from 'fabric';

const SavePNGButton = () => {
  const { config } = useSkuConfig();

  const saveCanvas = (canvas, orderNumber ="test", projectUuid="test") => {
    // remove the clipPath on the canvas if it exist
    canvas.value.clipPath = null;

    // if the canvas has print dimensions, group the canvas and scale it to the print dimensions
    if (config.print_dimensions) {
      console.log("This needs to be scaled to the print dimensions");
    }

    if (config.artboard) {
      console.log("This needs to be cropped to the artboard path on the canvas")
    }

    if (config.version === "1.1.0") {
      console.log("This is the new version of the canvas")
    }

    // Convert canvas to PNG data URL
  
    // If there is a selection, remove it
    const activeObject = canvas.value.getActiveObject();
    if (activeObject) {
      activeObject.set('active', false);
      canvas.value.discardActiveObject();
      canvas.value.renderAll();
    }
  
    // remove the layers for production
    const layers = ['guide', 'mask', 'edge'];
    for (const object of canvas.value.getObjects()) {
      if (layers.includes(object.id)) {
        canvas.value.remove(object);
      }
    }
  
    const scale = canvas.value.getZoom();
    const desiredMultiplier = 300 / 72;
    const effectiveMultiplier = desiredMultiplier / scale;
  
    const image = canvas.value.toDataURL({
      format: 'png',
      quality: 1,
      multiplier: effectiveMultiplier  // This will use a multiplier of 8.33
    });
  
    // If you'd like to allow the user to download the image:
    const link = document.createElement('a');
    link.href = image;
    link.download = `${orderNumber}-${projectUuid}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  };

  return (
    <NavyButton
    onClick={()=>saveCanvas(canvas)}
  >SAVE PNG</NavyButton>
  )
}

export default SavePNGButton