import { FormControl, Paper, TextField, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";

const EditToolbar = ({canvas}) => {
  // const [canvas, setCanvas] = useRecoilState(canvasAtom);
  
  const defaultText = canvas.value.getActiveObject()?.text || 
                      (canvas.value.getActiveObject()?._objects && canvas.value.getActiveObject()._objects[0].text);
  const [text, setText] = useState(defaultText);

  useEffect(() => {
    const activeObject = canvas.value.getActiveObject();
    
    const handleTextChange = (e) => {
      setText(e.target.text);
    };

    activeObject.on('text:changed', handleTextChange);

    const handleBlur = () => {
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.focus();
      document.body.removeChild(dummyInput);
    };

    const textField = document.getElementById("outlined-basic");
    if (textField) {
      textField.addEventListener('blur', handleBlur);
    }

    return () => {
      activeObject.off('text:changed', handleTextChange);
      if (textField) {
        textField.removeEventListener('blur', handleBlur);
      }
    };
  }, [canvas]);

  const handleChange = (event) => {
    const activeObject = canvas.value.getActiveObject();
    
    if (activeObject) {
      if (activeObject._objects) {
        activeObject._objects[0].text = event.target.value;
      } else {
        activeObject.text = event.target.value;
      }
      canvas.value.renderAll();
    }
  };

  return (
    <Toolbar style={{ paddingBottom: '10px' }}>
      <Paper style={{ width: '100%' }}>
        <FormControl fullWidth>
          <TextField
            className="app-text-input"
            id="outlined-basic"
            defaultValue={defaultText}
            multiline
            variant="outlined"
            onChange={handleChange}
          />
        </FormControl>
      </Paper>
    </Toolbar>
  );
};

export default EditToolbar;
