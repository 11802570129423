import styles from './Slider.module.css';
import { Switch } from "@/components/ui/switch"

const Slider = ({fileType, setFileType}) => {
  return <span className={styles.sliderContainer}>
    <span className={styles.sliderLabel}>SVG</span>
    <label className={styles.switch}>
      <Switch 
        checked={fileType === 'json'}
        onCheckedChange={() => setFileType(fileType === 'json' ? 'svg' : 'json')}
        // onChange={() => setFileType(fileType === 'json' ? 'svg' : 'json')}
      />
      {/* <input type="checkbox" 
        checked={fileType === 'json'}
        onChange={() => setFileType(fileType === 'json' ? 'svg' : 'json')}
      /> */}
      {/* <span className={`${styles.slider} ${styles.round}`}></span> */}
    </label>
    <span className={styles.sliderLabel}>JSON</span>
  </span>
}

export default Slider;