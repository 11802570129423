/* 
  The purpose of this component is to save the fabric JSON to S3.
  */

import React, { useState } from "react";
// packages
import axios from "axios";
// components
import { Button } from "@/components/Button";
import { toast } from "sonner";
import { useSkuConfig } from "@/hooks";
// signals
import { sku } from "@/signals/sku";
import { canvas } from "@/signals/canvas";

const SaveFabricJSONButton = ({filename, formRef}) => {
  // const [canvas] = useRecoilState(canvasAtom);
  const { config } = useSkuConfig('saveFabricJSONButton');
  const [Processing, setProcessing] = useState(false);

  const saveFabricJSON = async (e) => {
    e.preventDefault();
    // const formData = new FormData(formRef.current);
    const filename = config.file;
    // Check if filename is empty on the FORM

    if(!filename) {
      alert("You forgot to name the file dummy.")
      return null;
    }

    if(!filename.includes('.json')) {
      alert("You forgot to add .json to the end of the filename dummy.")
      return null;
    }

    setProcessing(true);
    console.log('Saving fabric JSON');

    const configData = canvas.value.toJSON(); // Convert canvas to JSON format
    const blob = new Blob([JSON.stringify(configData)], { type: 'application/json' });

    if (!sku.value || !configData) {
      console.error("SKU or config data is missing");
      return;
    }

    try {
      // First, get the pre-signed URL
      const presignedResponse = await fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url-json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filename: filename, filetype: 'application/json' }),
      });

      const { url } = await presignedResponse.json();

      // Then, use the pre-signed URL to upload directly to S3
      const uploadResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: blob,
      });

      if (uploadResponse.ok) {
        toast.success('Fabric JSON uploaded successfully');
        // await invalidateCache(); // Invalidate cache after successful upload
        setProcessing(false);
      } else {
        console.error('Upload failed:', await uploadResponse.text());
        setProcessing(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setProcessing(false);
    }
  };

  return <Button onClick={(e)=>saveFabricJSON(e)}>Save Fabric JSON</Button>;
};

export default SaveFabricJSONButton;
