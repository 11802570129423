import styles from './Icon.module.css'

const TextIcon = () => {
  return <svg
  fill="none"
  height="30"
  viewBox="0 0 30 30"
  width="30"
  xmlns="http://www.w3.org/2000/svg"
>
  <g className="g" clipPath="url(#clip0_9_231)">
    <path
      
      clipRule="evenodd"
      d="M0 0H30V7.5H26.25V3.75H16.875V26.25H22.5V30H7.5V26.25H13.125V3.75H3.75V7.5H0V3.75V0Z"
      fill="white"
      fillRule="evenodd"
    />
  </g>
  <defs className="defs">
    <clipPath className="clipPath" id="clip0_9_231">
      <rect className="rect" fill="white" height="30" width="30" />
    </clipPath>
  </defs>
</svg>
}

export default TextIcon;