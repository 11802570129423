import React, { useEffect } from 'react';
import hljs from 'highlight.js';
// Example of importing a dark theme style
import 'highlight.js/styles/atom-one-dark.css';
import styles from './Modal.module.css';

import { Button } from '@/components/Button';

const Modal = ({ content, language, onClose }) => {
    useEffect(() => {
        hljs.highlightAll();
    }, [content]);


    return (
        <div className={styles.modal}>
            {/* <div className="modal-content"> */}
            <div className={styles.modalContent}>
                <Button className="close" onClick={onClose}>&times;</Button>
                <pre>
                    <code className={`language-${language}`}>
                        {content}
                    </code>
                </pre>
            </div>
        </div>
    );
};

export default Modal;
