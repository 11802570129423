import Checkmark from "./Checkmark"

const IsFileLoaded = ({ label, isLoaded }) => {

    return (
        <span className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-text-white">
            {isLoaded ? <>✅ {label} </>: null} 
            {/* {isLoaded ? <><Checkmark /> {label} </>: null}  */}
        </span>
    )
}

export default IsFileLoaded