import { Button, Paper, Toolbar } from "@mui/material"
import { Stack } from "@mui/system"
import fonts from "@/utils/fonts"
// recoil
import { useRecoilValue } from "recoil"
import appModeAtom from "@/atoms/app-mode-atom"
// signals
import { canvas } from "@/signals/canvas"

const FontSelectToolbar = () => {
  const appMode = useRecoilValue(appModeAtom)

  const handleClick = (fontFamily) => {
    let activeObject = canvas.value.getActiveObject();
    // Check if it's a group and pick the first object
    if (activeObject?._objects && activeObject._objects.length > 0) {
      activeObject = activeObject._objects[0];
    }
    activeObject.set("fontFamily", fontFamily)
    canvas.value.renderAll()
  }

  return <Toolbar style={{
    height: '100px',
    paddingBottom:'10px'
    }}>
    <Paper style={{
      height: '100px',
      overflow: 'auto',
      width: '100%'}}>
      <Stack>
        {fonts.map((font, index) => {
          return <Button 
          key={index}
          className="tw-text-navy-main tw-bg-peachwik-gray-light"
          style={{
            margin: '0px',
            padding: '0px',
            textTransform: 'capitalize',
            fontFamily: font.font_family,
            fontSize: '2rem',
          }}
          onClick={() => handleClick(font.font_family)}
          >{appMode === 'ADMIN' ? `${font.alias} - ${font.font_file}` : font.alias}
          </Button>
        }
        )}

      </Stack>
    </Paper>
  </Toolbar>
}

export default FontSelectToolbar