import { atom, selector } from 'recoil'

const canvasAtom = atom({
  key: 'canvas',
  default: null, // or undefined
  dangerouslyAllowMutability: true
});

export default canvasAtom

