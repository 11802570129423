import { useRecoilState, useRecoilValue } from "recoil";
import { Image, Group } from "fabric";
import { useSkuConfig } from "@/hooks";

// signals
import { canvas } from "@/signals/canvas";


const WsmSaveButton = () => {
  const { config } = useSkuConfig();

  return (
    <button
      className="tw-bg-slate-500 hover:tw-bg-sky-500 tw-text-white tw-p-2 tw-m-2 tw-text-sm"
      onClick={saveImage}
    >
      SAVE PNG v1.1.0
    </button>
  );

  async function loadImageFromURL(url) {
    try {
      const image = await Image.fromURL(url); // Image loading attempt
      return image; // Return the image if successful
    } catch (error) {
      console.log('Error loading large image:', error.message);
      return null; // Return null or appropriate error handling
    }
  }

  async function cropCanvasToArtboard(artboardName) {
    if(canvas.value.clipPath) canvas.value.clipPath = null;
    console.log('cropping to :', artboardName);
    // const zoom = canvas.value.getZoom(); // may delte this
    canvas.value.setZoom(1);
    const clipPath = canvas.value.getObjects().find(obj => obj.id === config.clipPath);
    console.log('clipPath: ', clipPath)

    if (!clipPath) {
      console.error("Clip path not found.");
      return;
    }

    // We need to remove the clipPath from the canvas before we crop, offset and scale 
    const artboard = canvas.value.getObjects().find(obj => obj.id === config.artboard);
    console.log('canvas: ', canvas.value.width, canvas.value.height)
    // canvas.value.clipPath = artboard;
    // When cropping we want to set the canvas dimensions to match the clipPath
    canvas.value.setWidth(artboard.width);
    canvas.value.setHeight(artboard.height);

    canvas.value.renderAll();

    console.log("ben is the best")
    console.log('clipPath: ', 'Left: ' ,clipPath.left, 'Top: ', clipPath.top, 'Width: ', clipPath.width, 'Height: ', clipPath.height)
    console.log('artboard: ', 'Left: ' ,artboard.left, 'Top: ', artboard.top, 'Width: ', artboard.width, 'Height: ', artboard.height)
    console.log('canvas: ', 'Width: ', canvas.value.width, 'Height: ', canvas.value.height)

    const deltaX = artboard.left;
    const deltaY = artboard.top;

    console.log('deltaX: ', deltaX, 'deltaY: ', deltaY)

    // Group all objects except the clipPath
    const allObjects = canvas.value.getObjects().filter(obj => obj !== clipPath);
    const group = new Group(allObjects, {
      originX: 'left',
      originY: 'top',
    });

    // Adjust position of the group relative to the new canvas size
    group.set({
      left: -deltaX,
      top: -deltaY,
    }).setCoords();

    canvas.value.add(group)

    canvas.value.renderAll();
  }
  
  async function replaceImageById(id) {
    if (!config.image_maps) return; // No image to replace
    const smallImageObj = canvas.value.getObjects().find(obj => obj.id === id);
    // I'd also like to know it's index so we can insert the large image at the same position
    const smallImageIndex = canvas.value.getObjects().indexOf(smallImageObj);

    if(!smallImageObj) {
      console.log('This design does not have an image to replace');
      return;
    }

  if (smallImageObj) {

    const url = `${import.meta.env.VITE_CLOUDFRONT_CDN_SERVER_IMAGES_URL}/${config.image_maps[0].url}`;
    const largeImage = await loadImageFromURL(url);

    // Remove the smaller image
    canvas.value.remove(smallImageObj);

    // Correct scaling for largeImage to match the visual size of smallImageObj
    console.log('config', config)
    const scaleX = canvas.value.width / config.printDimensions.width / canvas.value.getZoom();
    const scaleY = canvas.value.height / config.printDimensions.height / canvas.value.getZoom();

    // Apply the calculated scaling factor to match the size of the smaller image on the canvas
    largeImage.set({
      left: 0,
      top: 0,
      scaleX: scaleX,
      scaleY: scaleY,
      angle: smallImageObj.angle,
    });

    // Insert the large image at the same index as the smaller image
    // insertAt method signature changed from insertAt(object, index) to insertAt(index, ...objects)
    canvas.value.insertAt(smallImageIndex, largeImage);
    canvas.value.renderAll();
  }
  
  }

  async function saveImage() {
    console.log('Saving image');
    // Before we save the image, we need to remove the canvas edge (if it exists)
    const edge = canvas.value.getObjects().find(obj => obj.id === 'edge');
    if (edge) canvas.value.remove(edge)

    // This function will replace the smaller image with the larger image
    // it's working with the fabric canvas object
    replaceImageById('design') 

    // // This also resizes the fabric canvas dimensions
    cropCanvasToArtboard(config.artboard);

    const multiplier = (config.printDimensions.width / canvas.value.width)

    const file = canvas.value.toDataURL({ format: 'png', multiplier });

    triggerDownload(file, 'test-image.png');
    window.dispatchEvent(new Event('resize'));
  
  }
}

function triggerDownload(dataUrl, filename) {
  const link = document.createElement('a');
  link.download = filename;
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up
}


export default WsmSaveButton;