import { createTheme } from "@mui/material";

const primaryMain = '#7b9b98'; // Mint
const primaryLight = '#b2c5c3'; // Light Mint
const secondaryMain = '#15263f'; // Navy
const secondaryDark = '#15263f'; // Coral
const secondaryLight = '#374c6d';  // Light Coral
const secondaryContrastText = '#ffffff'; // White
// const secondaryMain = '#c67c66'; // Coral
// const secondaryLight = '#f2b9a9';  // Light Coral
// const secondaryContrastText = '#f2b9a9'; // White
const neutralMain = '#15263F'; // Navy
const neutralAccent = '#f2b9a9'; // Light Coral
const neutralContrastText = '#ffffff'; // White

const grey = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242'
}

const navy = {
  50: '#e6ecff',
  100: '#c3d3ef',
  200: '#a5b4d4',
  300: '#8597bb',
  400: '#6e81a7',
  500: '#566c94',
  600: '#485e84',
  700: '#374c6d',
  800: '#283957',
  900: '#15263f'
}

const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: primaryMain,
      light: primaryLight,
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      contrastText: secondaryContrastText,
    },
    neutral: {
      main: neutralMain,
      accent: neutralAccent,
      contrastText: neutralContrastText,
    },
    inactive: {
      light: grey[50],
      main: grey[300],
      dark: grey[800],
      contrastText: '#fff',
    },
    navy: {
      light: navy[50],
      main: navy[900],
      dark: navy[900],
      contrastText: '#fff',
    },
  },

  components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '12px',
          },
        },
        variants: [
          {
            props: { variant: 'inactive' },
            style: {
              backgroundColor: grey[400],
              color: grey[800],
                '&:hover': {
                  backgroundColor: grey[400],
                },
            },
          },
          {
            props: { variant: 'attention' },
            style: {
              backgroundColor: '#FF00FF',
              color: grey[800],
                '&:hover': {
                  backgroundColor: '#FF0000',
                },
            },
          },
        ],
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            padding: '0px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            backgroundColor: navy[900],
            '&:hover': {
              backgroundColor: navy[700],
            },
            color: 'white',
            marginRight: '10px',
            borderRadius: '5px',
          },
        },
      },
    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'selected' &&
            {
              backgroundColor: '#b2c5c3',
              color: '#fff',
            }),
          ...(ownerState.variant === 'customer' &&
            {
              backgroundColor: '#c67c66',
              color: '#fff',
            }),
        }),
      },
    }, // End MuiAppBar
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#7b9b98',
          color: '#fff',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#000',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // color: 'gray',
        }
      }
    },
  },
};

const theme = createTheme(themeOptions)

export default theme;