import { atom, selector, selectorFamily } from 'recoil'
import axios from 'axios';
import { sku } from '@/signals/sku'

const configAtom = atom({
  key: 'config',
  default: {
    default_file_type: '',
    color_options: [],
    text_options: [],
    photo_options: [],
    swatch_options: [],
    templates: [],
  }
})

// New selector for fetching config based on sku
// THIS MAY NEED TO BE DELETED
export const configSelector = selector({
  key: 'configSelector',
  get: async ({get}) => {
    console.log("SKU inside selector: ", sku.value);  // Add this line
    if (!sku.value) return get(configAtom); // Return existing default if no SKU
    
    const url = `https://d1gjdc7iupt3xn.cloudfront.net/api/config/${sku.value}.json`
    console.log("Fetching config from: ", url);
    try {
      // Inside the try block, after fetching data
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
      throw new Error('Could not fetch config - this most likely means the config file does not exist. check /api/config/ for the missing config');
    }
  }
});

// Existing selectors
const templatesSelector = selector({
  key: 'templates',
  get: ({get}) => {
    const config = get(configAtom);
    return config.templates;
  }
});

const colorOptionsSelector = selector({
  key: 'color_options',
  get: ({get}) => {
    const config = get(configAtom)
    return config.color_options
  }
})

const textOptionsSelector = selector({
  key: 'text_options',
  get: ({get}) => {
    const config = get(configAtom)
    return config.text_options
  }
})

const photoOptionsSelector = selector({
  key: 'photo_options',
  get: ({get}) => {
    const config = get(configAtom)
    return config.photo_options
  }
})

const configUrlSelector = selector({
  key: 'config_url',
  get: ({get}) => {
    const config = get(configAtom)
    return config.config_url
  }
})



const configValueSelectorFamily = selectorFamily({
  key: 'configValueSelector',
  get: key => ({ get }) => {
    const config = get(configAtom);
    return config[key];
  },
});

// Selector specifically for default_file_type
const defaultFileTypeSelector = selector({
  key: 'defaultFileTypeSelector',
  get: ({ get }) => {
    return get(configValueSelectorFamily('default_file_type'));
  }
});

// Selector for 'svg'
const svgFileSelector = selector({
  key: 'svgSelector',
  get: ({ get }) => get(configValueSelectorFamily('svg')),
});

// Selector for 'json'
const jsonFileSelector = selector({
  key: 'jsonSelector',
  get: ({ get }) => get(configValueSelectorFamily('file')),
});





export default configAtom
export { templatesSelector, colorOptionsSelector, photoOptionsSelector, textOptionsSelector,
  configUrlSelector,
  defaultFileTypeSelector,
  svgFileSelector,
  jsonFileSelector
}