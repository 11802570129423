/**
 * Footer Component
 * 
 * This component is a toolbar located at the bottom of the application.
 * It displays different toolbars (`OptionsToolbar`, `TemplatesToolbar`, `TextToolbar`, `ActiveSelection`)
 * based on the current state of `toolbar`, which is a value fetched from a Recoil's atom.
 * 
 * The visibility of each toolbar is determined by the presence of specific keywords in the `toolbar` string.
 * For instance, when `toolbar` includes "main", the `OptionsToolbar` is visible.
 * 
 * In addition, this component includes a `TitleBar` that is always visible and displays the current state of `toolbar`.
 *
 * In future development, a `PhotoToolbar` will be added to this footer.
 */

import toolbarAtom from "@/atoms/toolbar-atom";
import ActiveSelection from "@/pages/Maker/components/Toolbars/ActiveSelection";
import TextToolbar from "@/pages/Maker/components/ToolbarText";
import TitleBar from "@/pages/Maker/components/ToolbarText/TitleBar";
import { AppBar } from "@mui/material";
import { forwardRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { OptionsToolbar } from "@/pages/Maker/components/Toolbars/OptionsToolbar";
import PhotoToolbar from "../ToolbarPhoto/PhotoToolbar";
import TemplatesToolbar from "../Toolbars/TemplatesToolbar";
import { footerHeightAtom } from "@/atoms/footer-height-atom";
import { useSetRecoilState } from "recoil";
import { useRef } from "react";
import { TOOLBAR_STATES } from "@/pages/Maker/components/Toolbars/toolbarStates";

const Footer = forwardRef((props, ref) => {
  const toolbar = useRecoilValue(toolbarAtom);
  const setFooterHeight = useSetRecoilState(footerHeightAtom);
  const footerRef = useRef(null);

  // This function helps the useEffect in the FabricCanvas component to resize the canvas
  useEffect(() => {
    if (footerRef.current) {
      const currentHeight = footerRef.current.offsetHeight;
      setFooterHeight(currentHeight);
    }
  }, [setFooterHeight, toolbar]); // Listening to toolbar changes as the toolbar affects the height
  

  const renderAppropriateToolbar = (toolbar) => {
    console.log('Toolbar state:', toolbar);
    
    if (toolbar.includes(TOOLBAR_STATES.MAIN)) return <OptionsToolbar />;
    if (toolbar === TOOLBAR_STATES.TEMPLATES) return <TemplatesToolbar />;
    if (toolbar.includes(TOOLBAR_STATES.TEXT)) return <TextToolbar />;
    if (toolbar.includes(TOOLBAR_STATES.ACTIVE_SELECTION) || 
        toolbar.includes("path") || 
        toolbar.includes("rect")) return <ActiveSelection />;
    
    // Debug line to check if this condition is ever met
    if (toolbar.includes(TOOLBAR_STATES.PHOTO)) {
      console.log('PhotoToolbar should be rendered');
      return <PhotoToolbar />;
    }
  
    return null;
  };
    

  return (<>
    <AppBar
      id='app-bar'
      ref={footerRef}
      position="sticky"
      sx={{
        top: 'auto',
        bottom: 0,
        // width: '100%',
        height: 'auto',
        minHeight: '150px'
      }}
    >

      <TitleBar selected={toolbar} />
      {renderAppropriateToolbar(toolbar)}
    </AppBar>
  </>
  );
})

Footer.displayName = "Footer";
export default Footer;