import React from 'react';

const LetterSpacingIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.437 17.7782H0L4.99901 0H10.6192L15.6182 17.7782H11.1812L10.3061 14.3753H5.31212L4.437 17.7782ZM7.86827 4.89594L9.37741 10.7641H6.24081L7.74995 4.89594H7.86827ZM19.0258 0L22.1317 12.535H22.25L25.3559 0H30L25.001 17.7782H19.3808L14.3818 0H19.0258ZM6.82741 29.8062L0.844758 25.5811C0.477633 25.3218 0.477633 24.9015 0.844758 24.6422L6.82741 20.4171C7.19453 20.1578 7.78976 20.1578 8.15689 20.4171C8.52401 20.6764 8.52401 21.0967 8.15689 21.356L3.77906 24.4477H4.20002H25.4726L21.0947 21.356C20.7276 21.0967 20.7276 20.6764 21.0947 20.4171C21.4619 20.1578 22.0571 20.1578 22.4242 20.4171L28.4069 24.6422C28.774 24.9015 28.774 25.3218 28.4069 25.5811L22.4242 29.8062C22.0571 30.0655 21.4619 30.0655 21.0947 29.8062C20.7276 29.5469 20.7276 29.1266 21.0947 28.8673L25.4726 25.7756H4.20002H3.77906L8.15689 28.8673C8.52401 29.1266 8.52401 29.5469 8.15689 29.8062C7.78976 30.0655 7.19453 30.0655 6.82741 29.8062Z" fill="white"/>
    </svg>
  );
};

export default LetterSpacingIcon;
