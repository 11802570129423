import styles from './AdminFontChart.module.css';
import fonts from '@/utils/fonts.js';

const AdminFontChart = () => {

  return (
    <div>
      <h1>AdminFontChart</h1>
      <div className={styles.fontChart}>
      {fonts.map((font, index) => (
          <div key={index} className={styles.fontChartItem}>
            {/* <div style={{fontFamily: font.font_family}} className={styles.fontChartItemLabel}>{font.font_family}</div> */}
            <div style={{fontFamily: font.font_family}} className={styles.fontChartItemLabel}>{font.alias}</div>
          </div>
        ))}
    </div>
    </div>
  );
} 

export { AdminFontChart };