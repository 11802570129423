import styles from "./ButtonGroup.module.css";

const ButtonGroup = ({ children }) => {
  return (
    <div className={styles.buttonGroup}>
      {children}
    </div>
  );
}

export default ButtonGroup;