// This is the Color Options Toolbar

import { Toolbar } from "@mui/material"
import HorizontalScroll from "@/components/HorizontalScroll";
import ButtonGroup from "@/components/ButtonGroup";
import {Button} from "@/components/Button";
import ColorIcon from "@/components/Icons/ColorIcon";

import React from "react";

// import toolbars
import { useRecoilState } from "recoil";

import toolbarAtom from "@/atoms/toolbar-atom";
import MainSwatchStack from "@/pages/Maker/components/SwatchGroups/MainSwatchStack";


const ActiveSelection = () => {
  const [toolbar, setToolbar] = useRecoilState(toolbarAtom)

  return true && <>
    {toolbar === 'activeSelection > color' && <MainSwatchStack />}

    {toolbar === 'activeSelection' &&
      <Toolbar>
        <HorizontalScroll>
          <ButtonGroup>
            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('activeSelection > color')}
              icon={<ColorIcon />}
            >color</Button>
          </ButtonGroup>
        </HorizontalScroll>
      </Toolbar>
    }

  </>
}

export default ActiveSelection