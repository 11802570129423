import React, { forwardRef } from 'react';
import { Box } from "@mui/system";
import { useViewportHeight } from '@/hooks/useViewportHeight';

const AppContainer = forwardRef((props, ref) => {
  const { children } = props;
  useViewportHeight();

  return (
    <Box
      id="app-container"
      ref={ref}
      position="fixed"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(var(--vh, 1vh) * 100)", // Note the change here
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
        fontSize: '1.2em'
      }}
    >
      {children}
    </Box>
  );
});

AppContainer.displayName = 'AppContainer';

export default AppContainer;
