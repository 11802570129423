import styles from './Panel.module.css';
const Panel = ({title, children}) => {
  return (
    <div className={styles.panel}>
      <h1 className={styles.panelTitle}>{title}</h1>
      {children}
    </div>
  );
}

export default Panel;