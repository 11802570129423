/**
* This function is called when the user makes a programmitic selection of objects from the toolbar.
**/
import * as fabric from 'fabric'

const makeSelection = (canvas: any, ids: Array<string>) => {
    canvas.value.discardActiveObject();
    const selObjs = canvas.value._objects.filter(o => {
        if (ids.includes(o.id)) return o
    })

    var sel = new fabric.ActiveSelection(selObjs, {
        canvas: canvas.value,
    });

    sel.hasBorders = false
    sel.selectable = false
    sel.hasControls = false
    sel.lockMovementX = true
    sel.lockMovementY = true
    canvas.value.renderAll()

    canvas.value.setActiveObject(sel);
    canvas.value.requestRenderAll();

}


export { makeSelection }