// Header.js
import React from 'react';
import styles from './Header.module.css';
import ButtonGroup from '@/components/ButtonGroup';
import {Button} from '@/components/Button';
import SkuInput from './SkuInput';
import SaveFabricJSONButton from '../SaveFabricJSONButton';
import AddUploadPhotosButton from './AddUploadPhotosButton';
import { AdminColorGrid, AdminFontChart } from '@/components/Charts';

const Header = ({ children, sku, setSvgContent,
  svgLoaded, fabricJSONLoaded,
 svgFileName, setSvgFileName, fabricJSONFileName, setFabricJSONFileName
}) => {
  const [colorChartVisible, setColorChartVisible] = React.useState(false);
  const [fontChartVisible, setFontChartVisible] = React.useState(false);
  return (
    <div className={styles.header}>
      <ButtonGroup>
        <Button onClick={()=>setColorChartVisible(!colorChartVisible)}>Color Chart</Button>
        <Button onClick={()=>setFontChartVisible(!fontChartVisible)}>Font Chart</Button>
        <AddUploadPhotosButton />
      </ButtonGroup>
      {colorChartVisible && <AdminColorGrid /> }
      {fontChartVisible && <AdminFontChart /> }
      <ButtonGroup>
        {children}
      </ButtonGroup>
    </div>
  );
}

export default Header;
