const colors = [
    "#FFFFFF",
    '#000000',
    "#FACBCB",
    "#F6A7A9",
    "#EB8080",
    "#EF5451",
    "#ED1C24",
    "#C52627",
    "#991B27",
    "#F8BBCF",
    "#F490B1",
    "#F06392",
    "#EE4666",
    "#D53392",
    "#9D2063",
    "#5E062A",
    "#F280AA",
    "#EF4581",
    "#ED1459",
    "#C51E63",
    "#DEBDDB",
    "#C694C3",
    "#AB6CAD",
    "#9B51A0",
    "#783293",
    "#4B2D87",
    "#C489BC",
    "#A85CA4",
    "#963588",
    "#793C6A",
    "#5D2C67",
    "#D1C3E0",
    "#B39CCB",
    "#9175B5",
    "#775CA7",
    "#755D86",
    "#543D98",
    "#362F8B",
    "#9E89C0",
    "#6B5EA9",
    "#C5C9E6",
    "#A1A8D5",
    "#7B86C2",
    "#5E6BB2",
    "#4555A5",
    "#293990",
    "#2D3079",
    "#223355",
    "#8E99CD",
    "#596AB2",
    "#BDDDF4",
    "#95C8EC",
    "#70B2E2",
    "#3374BA",
    "#14499E",
    "#8FAECC",
    "#5881C1",
    "#4766B0",
    "#E2F4FD",
    "#B5E4FA",
    "#84D2F6",
    "#44B3E6",
    "#1B87C9",
    "#00579B",
    "#85D5F7",
    "#56C0EE",
    "#44A9E0",
    "#0A85C4",
    "#E1F3F7",
    "#B6E2EB",
    "#89D3E2",
    "#5AC7DA",
    "#0297A7",
    "#01838F",
    "#016065",
    "#9CD9E6",
    "#71CDDD",
    "#5ECAE7",
    "#00B7D3",
    "#C5E6E3",
    "#96D3C5",
    "#80CBC4",
    "#4CB6AC",
    "#92B7B1",
    "#05897B",
    "#007A6C",
    "#014D41",
    "#83DDDD",
    "#23BAB2",
    "#5BB6C0",
    "#009886",
    "#C8E5C9",
    "#A5D5A7",
    "#81C785",
    "#4DAF4E",
    "#378E43",
    "#1E5F30",
    "#BFE1C3",
    "#84CBA1",
    "#5BBC6B",
    "#DCECC8",
    "#C6E0A6",
    "#AED580",
    "#9CCC65",
    "#7CB342",
    "#568B3E",
    "#346A34",
    "#CEE18B",
    "#B8D54B",
    "#8EC73F",
    "#C4CEAB",
    "#A0AE85",
    "#707C57",
    "#E6EB9C",
    "#DBE578",
    "#CEDC38",
    "#C0CA33",
    "#AFB436",
    "#9D9D36",
    "#82782F",
    "#F0EE84",
    "#E8E73E",
    "#AFD136",
    "#FEF8C5",
    "#FEF59F",
    "#FFF278",
    "#FEEA3A",
    "#FDD837",
    "#F9BF2C",
    "#F9A822",
    "#F57F20",
    "#FAF291",
    "#FEE900",
    "#FED600",
    "#FFECB2",
    "#FFE081",
    "#FFD54F",
    "#FEC111",
    "#F9A01B",
    "#F78F1E",
    "#F37021",
    "#FEE580",
    "#FFD740",
    "#FFC50D",
    "#FBAA19",
    "#FFE0B2",
    "#FECC81",
    "#FCB64D",
    "#FAA629",
    "#F78C1E",
    "#ED6D23",
    "#E55325",
    "#FFD181",
    "#FAAA43",
    "#F7901E",
    "#F36E21",
    "#FCD9C3",
    "#F9AA92",
    "#F79D71",
    "#E28966",
    "#D16646",
    "#AF3D23",
    "#C59789",
    "#D88970",
    "#AF5B23",
    "#5E3311",
    "#F0E7C5",
    "#D8C8B2",
    "#A78F78",
    "#856B56",
    "#5F493B",
    "#3A2A21",
    "#EEEEEE",
    "#E0E0E0",
    "#BDBDBD",
    "#9E9D9E",
    "#757576",
    "#616261",
    "#434343",
    "#CFD8DC",
    "#B0BEC5",
    "#90A4AE",
    "#78909C",
    "#607D8B",
    "#546F7A",
    "#455B64",
    "#37474F",
    "#263238"
]

export { colors };