import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { sku } from '@/signals/sku';

const fetchConfig = async (sku) => {
  console.log('inside fetchConfig', sku);
  const url = `${import.meta.env.VITE_CLOUDFRONT_CDN_API_CONFIG_URL}/${sku}.json`;
  console.log('url', url)
  try {
    const response = await axios.get(url);
    console.log('useSkuConfig: fetchConfig: response', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching config: ', error.message)
    return {
      sku,
      status: "NEW",
    }
    console.error('Error fetching config:', error.message);
    throw new Error('There is no config file for this SKU');
    if (axios.isAxiosError(error)) {
      // Check for 403 or 404 status code in the error response
      if (error.response && (error.response.status === 403 || error.response.status === 404)) {
        console.log(`Error fetching config for SKU ${sku.value}:`, error.message);
        return null; // Return null or a default config object
      }
    }
    // Rethrow the error for any other types of errors
    throw error;
  }
};


export function useSkuConfig() {
  console.log('sku from useSkuConfig', sku.value  )
  const queryClient = useQueryClient(); // To use for cache invalidation

  const { data, isLoading, isError, refetch } = useQuery(
    ['config', sku.value], 
    () => fetchConfig(sku.value), 
    {
      enabled: !!sku.value,
      retry: false,
      // Add other configurations as needed
    }
  );

  console.log('useSkuConfig: data', data);
  console.log('useSkuConfig: isLoading', isLoading);
  console.log('useSkuConfig: isError', isError);

  // Optionally, expose a method for manual cache invalidation and refetching
  const invalidateAndRefetch = async () => {
    await queryClient.invalidateQueries(['config', sku.value]);
    await refetch();
  };

  return { config: data, isLoading, isError, refetch: invalidateAndRefetch };
}
