import styles from './AdminColorGrid.module.css';
import { colors } from "@/utils/colors";
const AdminColorGrid = () => {

  const size = {
    small: '25px',
    medium: '40px',
    large: '100px'
  }


   return (
    <div className={styles.colorGridContainer}>
        {colors.map((color, index) => (
          <div className={styles.colorGridItem}
            key={index}
            style={{
              backgroundColor: color,
              border: color === '#FFFFFF' ? '1px solid black' : 'none'
            }}
          >&nbsp;</div>
        ))}
    </div>
  )

}

export {AdminColorGrid}