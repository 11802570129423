import React, {useState} from "react";
import { Point } from "fabric";
// MUI
import { Toolbar } from "@mui/material"
// Pages
import MainSwatchStack from "@/pages/Maker/components/SwatchGroups/MainSwatchStack";
// Components
import { Button } from "@/components/Button";
import ButtonGroup from "@/components/ButtonGroup";
import HorizontalScroll from "@/components/HorizontalScroll";
import { EditIcon, FontIcon, ColorIcon, FontSizeIcon, FormatIcon, NudgeIcon, LineHeightIcon } from "@/components/Icons";
// import toolbars
import EditToolbar from "./EditToolbar";
import FontSelectToolbar from "./FontSelectToolbar";
import FontSizeToolbar from "./FontSizeToolbar/FontSizeToolbar";
import FormatToolbar from "./FormatToolbar";
import NudgeToolbar from "./NudgeToolbar";
import LineHeightToolbar from "./LineHeightToolbar";
import LetterSpacingToolbar from "./LetterSpacingToolbar";
// Recoil State
import { useRecoilState } from "recoil";
import toolbarAtom from "@/atoms/toolbar-atom";
import selectionTypeAtom from "@/atoms/selection-type-atom";
// Signals
import { canvas } from "@/signals/canvas";
// icons
import LetterSpacingIcon from "@/components/Icons/LetterSpacingIcon";
import ZoomIcon from "@/components/Icons/ZoomIcon";

const TextToolbar = () => {
  
  const [toolbar, setToolbar] = useRecoilState(toolbarAtom)
  const [selectionType] = useRecoilState(selectionTypeAtom)
  const visible = selectionType === 'text'
  // quick zoom functionality
  const [quickZoom, setQuickZoom] = useState(false)
  const [initialZoom, setInitialZoom] = useState(canvas.value.getZoom())

  function handleQuickZoom() {
    console.log('Initial Zoom:', initialZoom);
    console.log('Current Zoom:', canvas.value.getZoom());
  
    if (!quickZoom) {
      // we are not yet zoomed in, zoom in to the active object
      const activeObject = canvas.value.getActiveObject();
      if (activeObject) {
        // just making sure we have an active object
        const viewportCenter = new Point(canvas.value.getWidth() / 2, canvas.value.getHeight() / 2);

        const objectCenter = activeObject.getCenterPoint();
        const boundingRect = activeObject.getBoundingRect(true);
        
        console.log('Object Center:', objectCenter);
        console.log('Bounding Rect:', boundingRect);
  
        // Calculate zoom factor to make the active object fill most of the canvas
        const zoomFactor = Math.min(
          canvas.value.getWidth() / boundingRect.width * canvas.value.getZoom(),
          canvas.value.getHeight() / boundingRect.height * canvas.value.getZoom()
        ) * 0.8; // 0.8 for some margin

        console.log("canvas.value.getWidth()", "boundingRect.width", "canvas.value.getHeight()", "boundingRect.height");
        console.log(canvas.value.getWidth(), boundingRect.width, canvas.value.getHeight(), boundingRect.height);
  
        console.log('Zoom Factor:', zoomFactor);
  
        // Calculate effective zoom level
        const effectiveZoomLevel = initialZoom + (1 - initialZoom) * zoomFactor; // Increase initialZoom proportionally
        console.log('Effective Zoom Level:', effectiveZoomLevel);
  
        // Calculate the point to zoom into, considering the current zoom level and viewport transform
        const zoomPoint = new Point(
          objectCenter.x ,
          objectCenter.y
        );

         // Center the object in view while zooming
        const translateX = viewportCenter.x - objectCenter.x * effectiveZoomLevel;
        const translateY = viewportCenter.y - objectCenter.y * effectiveZoomLevel;

        // const zoomPoint = new Point(
        //   (objectCenter.x - canvas.value.viewportTransform[4]) / canvas.value.getZoom(),
        //   (objectCenter.y - canvas.value.viewportTransform[5]) / canvas.value.getZoom()
        // );
  
        console.log('Zoom Point:', zoomPoint);

  
        // canvas.value.zoomToPoint(zoomPoint, effectiveZoomLevel);
        canvas.value.viewportTransform = [effectiveZoomLevel, 0, 0, effectiveZoomLevel, translateX, translateY];

        // canvas.value.zoomToPoint(zoomPoint, 1);
        canvas.value.renderAll();
      }
    } else {
      // Deactivating quick zoom, reset to initialZoom and initial viewport position
      canvas.value.setViewportTransform([1, 0, 0, 1, 0, 0]); // Reset pan
      canvas.value.setZoom(initialZoom);
      canvas.value.renderAll();
  
      console.log('Reset Zoom:', canvas.value.getZoom());
    }
  
    setQuickZoom(!quickZoom);
    console.log('Post-operation Zoom:', canvas.value.getZoom());
  }

  // Render 
  return <>
    {toolbar === 'i-text > edit' && <EditToolbar canvas={canvas} />}
    {toolbar === 'i-text > font' && <FontSelectToolbar />}
    {toolbar === 'text > color' && <MainSwatchStack includeSwatches={false} />}
    {toolbar === 'text > fontsize' && <FontSizeToolbar />}
    {toolbar === 'text > format' && <FormatToolbar />}
    {toolbar === 'text > nudge' && <NudgeToolbar />}
    {/* {toolbar  === 'text > layer' && <LayerToolbar />} */}
    {/* {toolbar  === 'text > transparency' && <TransparencyToolbar />} */}
    {toolbar === 'text > line height' && <LineHeightToolbar />}
    {toolbar === 'text > letter spacing' && <LetterSpacingToolbar />}


    {toolbar.includes('text') && !toolbar.includes('>') &&
      <Toolbar>
        <HorizontalScroll>
          <ButtonGroup>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('i-text > edit')}
              icon={<EditIcon />}
            >Edit</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('i-text > font')}
              icon={<FontIcon />}
            >Font</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('text > color')}
              icon={<ColorIcon />}
            >Color</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('text > fontsize')}
              icon={<FontSizeIcon />}
            >Font Size</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('text > format')}
              icon={<FormatIcon />}
            >Format</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('text > nudge')}
              icon={<NudgeIcon />}
            >Nudge</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('text > line height')}
              icon={<LineHeightIcon />}
            >Line Height</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => setToolbar('text > letter spacing')}
              icon={<LetterSpacingIcon rotate={true} />}
            >Letter Spacing</Button>

            <Button
              variant="navy icon-stacked"
              onClick={() => handleQuickZoom()}
              icon={<ZoomIcon />}
            >Quick Zoom</Button>

          </ButtonGroup>
        </HorizontalScroll>
      </Toolbar>
    }

  </>
}

export default TextToolbar