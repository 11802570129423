import { Paper, Slider, Toolbar } from "@mui/material";
import { useState } from "react";
import Typography from "@mui/material/Typography";
// signals
import { canvas } from "@/signals/canvas";

function LetterSpacingToolbar() {
  let activeObject = canvas.value.getActiveObject();
  // Check if it's a group and pick the first object
  if (activeObject && activeObject._objects && activeObject._objects.length > 0) {
    activeObject = activeObject._objects[0];
  }
  // get line height from active object
  const [letterSpacing, setLetterSpacing] = useState(activeObject.get("charSpacing"))

  const handleChange = (event, newValue) => {
    activeObject.set({charSpacing: newValue});
    setLetterSpacing(newValue);
    canvas.value.renderAll();
  };

  return ( <>
  {/* center contents of toolbar */}
    <Toolbar
      style={{
        paddingBottom:'10px'
      }}
    >
    <Paper style={{
      height: '100px',
      overflow: 'auto',
      width: '100%'}}>
      <Typography
       align="center"
       gutterBottom>Letter Spacing: {letterSpacing}</Typography>
      <Slider
        min={-500}
        step={10}
        max={500}
        color="secondary"
        size="small"
        defaultValue={letterSpacing}
        aria-label="Small"
        valueLabelDisplay="auto"
        onChange={handleChange}
      />
      </Paper>
    </Toolbar>
    </>
   );
}

export default LetterSpacingToolbar;