const PhotoInstructions = () => {
  return (
    <div className="photo-instructions">
      <p>Upload your photo(s) from your device to your gallery by clicking the upload button.</p>
      <img src={`${import.meta.env.VITE_CLOUDFRONT_CDN_APP_IMAGES_URL}/photo-instructions-upload.png`} alt="Upload Button"
        style={{ height: '50px' }}
      />
      <p>Once your photo(s) have been uploaded, click on the photo you would like to use from your gallery to select it.</p>
      <p>Click the "Add Photo" button to add your photo to the design.</p>
      <img src={`${import.meta.env.VITE_CLOUDFRONT_CDN_APP_IMAGES_URL}/photo-instructions-add.png`} alt="Add Photo Button"
      style={{ height: '130px' }}
      />
      <p>Click the "Delete Photo" button to remove your photo from your gallery.</p>
    </div>
  )
}

export default PhotoInstructions