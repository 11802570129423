import { useQuery } from 'react-query';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { useSkuConfig } from '@/hooks/useSkuConfig';
import { shipstationProjectDataAtom } from '@/atoms';
// Signals
import { sku } from '@/signals/sku';

const fetchFabricJSON = async (url) => {
  const { data } = await axios.get(url);
  console.log('fabricJSON', data);
  return data;
};

const useSerializedFabricCanvasJSON = (shouldILoadJSON = true) => {
  if (!shouldILoadJSON) {
    // Early return with indication that no SVG file should be loaded
    return { data: null, error: "shouldILoadJSON was false", isLoading: false };
  }
  console.log('inside useSerializedFabricCanvasJSON');
  const { config } = useSkuConfig();
  const file = config.file;
  const shipstationProjectData = useRecoilValue(shipstationProjectDataAtom);

  // conditionally load from a saved customer project if we are in that context
  // otherwise we should load based on sku
  let url = '';
  if(!shipstationProjectData) url = `${import.meta.env.VITE_CLOUDFRONT_CDN_URL}/json/${file}`;
  if(shipstationProjectData) url = `${shipstationProjectData.payload.project.canvasUrl}`;

  const queryKey = ['fabricJSON', sku.value]; // Ensures the query is unique per SKU
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => fetchFabricJSON(url),
    {
      enabled: !!sku.value && shouldILoadJSON, // Only run the query if sku is not null/undefined and shouldILoadJSON is true
      // This takes the place of the early return and keeps the hook calls in a consistent order
      // Additional options like staleTime, cacheTime can be configured here
    }
  );

  console.log('Fabric JSON data', data);

  return { data, isLoading, error };
};

export default useSerializedFabricCanvasJSON;
