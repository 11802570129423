import styles from './PhotoCard.module.css';
import React, { useState } from 'react';
import Confirm from '@/components/Dialog/Confirm';

const PhotoCardControls = ({ handleAdd, handleDelete }) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const showDeleteConfirm = () => {
    setIsConfirmVisible(true);
  };

  const hideDeleteConfirm = () => {
    setIsConfirmVisible(false);
  };

  return (
    <>
      {isConfirmVisible && (
        <Confirm
          title="Delete Photo"
          message="Are you sure you want to delete this photo?"
          onConfirm={() => {
            handleDelete();
            hideDeleteConfirm();
          }}
          onCancel={hideDeleteConfirm}
        />
      )}
      <div className={styles.photoCardControls}>
        <button className={styles.photoCardControlButton} onClick={handleAdd}>ADD</button>
        <button className={styles.photoCardControlButton} onClick={showDeleteConfirm}>DELETE</button>
      </div>
    </>
  );
};

const PhotoCard = ({ image, selectedImageIndex, index, handleImageClick, handleAdd, handleDelete }) => {
  return (
    <div className={styles.photoCard}>
      <img
        key={index}
        src={URL.createObjectURL(image.thumbnail)}
        height={131}
        width={131}
        alt={image.name}
        style={{
          border:
            selectedImageIndex === index ? '5px solid yellow' : 'none',
          cursor: 'pointer',
        }}
        onClick={() => handleImageClick(index)}
      />
      {selectedImageIndex === index ?
      <PhotoCardControls 
        handleAdd={handleAdd}
        handleDelete={handleDelete}
      />
      : null}
    </div>
  );
};

export default PhotoCard;
