import * as fabric from 'fabric';
import { useEffect } from 'react';

// The whole purpose of this hook is to add a custom rotate control
// to all fabric objects. This will be where all global custom controls
// are added.

function useFabricControls(canvas) {

    useEffect(() => {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = 'white';
    fabric.Object.prototype.cornerSize = 12;
    fabric.Object.prototype.cornerStyle = 'circle';
    // fabric.Object.prototype.borderColor = 'yellow';
    // fabric.Object.prototype.borderScaleFactor = 2;
    fabric.Object.prototype.padding = 5;
    // fabric.Object.prototype.borderDashArray = [8, 5];
    // This allows for marching ants effect on the border
    fabric.Object.prototype.hasRotatingPoint = true;

    const rotateIconUrl = `${import.meta.env.VITE_CLOUDFRONT_CDN_APP_IMAGES_URL}/rotate-icon-fabric-control.svg`; // URL to your rotate icon

    // Load the rotate icon image
    const rotateImg = new Image();
    rotateImg.onload = function () {
        // Define the rotate control
        const rotateControl = new fabric.Control({
            x: 0, // Position on the x-axis
            y: -0.5, // Position on the y-axis
            offsetY: -40, // Adjust based on the size of the control, move the icon up
            cursorStyle: 'crosshair', // Customize the cursor style
            actionHandler: fabric.controlsUtils.rotationWithSnapping, // Action handler for rotation
            actionName: 'rotate', // Action name
            render: function(ctx, left, top, styleOverride, fabricObject) {
                const size = this.cornerSize;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(rotateImg, -size / 2, -size / 2, size, size);
                ctx.restore();
            },
            cornerSize: 24 // Customize the size of the control
        });

        // Assign the custom rotate control to all fabric objects
        fabric.Object.prototype.controls.mtr = rotateControl;

    };
    rotateImg.src = rotateIconUrl;

}, []);

}

export { useFabricControls };
