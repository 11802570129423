// signals
import { canvas } from "@/signals/canvas";
// components
import { NavyButton } from "@/components/Button";


const SaveSVGButton = () => {
  const exportSVG = async (canvas) => {
    // Prep the zoom
    // const printScale = 1
    // canvas.setZoom(1 * printScale);

    
    // // Prep the canvas dims for export
    // const background = canvas.getObjects().find((obj) => obj.id === 'background');
    // canvas.height = background.height * printScale;
    // canvas.width = background.width * printScale;
    // canvas.renderAll()
    
    // Obtain the SVG string representation of the canvas content
    const svgString = canvas.toSVG();
  
    // Create a Blob object from the SVG string
    const blob = new Blob([svgString], { type: "image/svg+xml" });
  
    // Create a link element
    const link = document.createElement('a');
  
    // Set the download attribute with a filename
    link.download = 'canvas_image.svg';
  
    // Create a URL for the Blob and set it as the href attribute
    link.href = window.URL.createObjectURL(blob);
  
    // Append the link to the body
    document.body.appendChild(link);
  
    // Programmatically click the link to trigger the download
    link.click();
  
    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <NavyButton
      onClick={() => exportSVG(canvas)}
    >
      SAVE SVG
    </NavyButton>
  )
}

export default SaveSVGButton