import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
import { useSkuConfig } from './useSkuConfig';
// Signals
import { sku } from '@/signals/sku';

// Define the fetch function outside of the hook to avoid recreating it on each render
const fetchSvg = async (svgFileName) => {
  console.log("fetching svg file", svgFileName);
  if (!svgFileName) throw new Error("SVG file name is required to fetch SVG.");

  const url = `/svg/${svgFileName}`;
  const response = await fetch(url);

  // Directly throw if not OK, no need for try-catch here unless you have other specific reasons
  if (!response.ok) {
    // return 'There was a problem yo';
    throw new Error('Unable to fetch SVG file, there may not be one for this SKU, or the filename may be incorrectly mapped in the config file.');
  }

  return response.text(); // Assuming the SVG content is returned directly as text
};


const useSvgFile = (shouldILoadSVG = true) => {
  if (!shouldILoadSVG) {
    // Early return with indication that no SVG file should be loaded
    return { data: null, error: "shouldILoadSvg was false", isLoading: false };
  }

  console.log('useSvgFile: useSkuConfig')
  const { config } = useSkuConfig();
  
  console.log('config from useSvgFile', config);
  const svgFileName = config?.svg; // Fallback if needed

  console.log('!!sku && shouldILoadSVG:', !!sku.value && shouldILoadSVG, shouldILoadSVG,typeof(!!sku.value && shouldILoadSVG))

  const { data, error, isLoading } = useQuery(
    ['fetchSvg', svgFileName], 
    () => fetchSvg(svgFileName),
    {
      enabled: !!sku && shouldILoadSVG,
      retry: 0, // Important: prevents retrying after initial failure
      onError: (err) => {
        console.error("Failed to fetch SVG:", err);
      }
    }
  );

  return { data, error, isLoading };
};

export default useSvgFile;
