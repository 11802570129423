import styles from './IconBox.module.css'
import ColorIcon from '@/components/Icons/ColorIcon'
import PhotoIcon from '@/components/Icons/PhotoIcon'
import TextIcon from '@/components/Icons/TextIcon'

const getIcon = (icon) => {
  switch (icon) {
    case 'color':
      return <ColorIcon />
    case 'photo':
      return <PhotoIcon />
    case 'text':
      return <TextIcon />
    default:
      return <ColorIcon />
  }
}

const IconBox = ({icon}) => {
  return (
  <div className={styles.iconBox}>{getIcon(icon)}</div>
  )
}

export default IconBox