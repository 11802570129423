const Artboard = ({artboard, setArtboard}) => {
  console.log('artboard: ', artboard)

  const handleChange = (e) => {
    setArtboard(e.target.value)
  }

  return (
    <div className="tw-p-4">
      <select className="tw-w-full tw-p-4"
        value={artboard}
        onChange={handleChange}
      >
        <option value="matte">matte</option>
        <option value="canvas">canvas</option>
        <option value="background">background</option>
      </select> 
  </div>
  )
}

export default Artboard