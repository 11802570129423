import styles from './Confirm.module.css';
import { Button } from '@/components/Button';
import ButtonGroup from '@/components/ButtonGroup';

const Alert = ({ title, message, buttonLabel, onConfirm }) => {
  return (
    <div className={styles.confirmWrapper}>
      <div className={styles.confirm}>
        <div className={styles.title}>{title}</div>
        <p className={styles.message}>
          {message}
        </p>
        <hr />
        <ButtonGroup>
          <Button
            variant="navy"
            onClick={onConfirm}>{buttonLabel}</Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default Alert;