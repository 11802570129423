// fonts are stored here, and properties are defined below.

const fonts = [
    {
      "font_file": "Absinthe-Base.woff",
      "font_family": "Absinthe-Base",
      "alias": "vintage label"
    },
    {
      "font_file": "Absinthe-Full.woff",
      "font_family": "Absinthe-Full",
      "alias": "vintage label outline"
    },
    {
      "font_file": "Albertya.woff",
      "font_family": "Albertya",
      "alias": "albertina"
    },
    {
      "font_file": "AmaticSC-Regular.woff",
      "font_family": "AmaticSC-Regular",
      "alias": "automatic type"
    },
    {
      "font_file": "AmberWhiskeyAged.woff",
      "font_family": "AmberWhiskeyAged",
      "alias": "spectacle island"
    },
    {
      "font_file": "Amellyta.woff",
      "font_family": "Amellyta",
      "alias": "amethyst"
    },
    {
      "font_file": "Ameston Sans.woff",
      "font_family": "AmestonSans",
      "alias": "elephant regular"
    },
    {
      "font_file": "Amistella.woff",
      "font_family": "Amistella",
      "alias": "anabella"
    },
    {
      "font_file": "Anatomi.woff",
      "font_family": "Anatomi",
      "alias": "heather rose"
    },
    {
      "font_file": "Andesia.woff",
      "font_family": "Andesia",
      "alias": "anderson script"
    },
    {
      "font_file": "Arvo-Regular.woff",
      "font_family": "Arvo",
      "alias": "arlo slab"
    },
    {
      "font_file": "Belalang.woff",
      "font_family": "belalang",
      "alias": "daisy script"
    },
    {
      "font_file": "Belanga.woff",
      "font_family": "Belanga",
      "alias": "bella fancy"
    },
    {
      "font_file": "BerkshireSwash-Regular.woff",
      "font_family": "BerkshireSwash-Regular",
      "alias": "fairytale regular"
    },
    {
      "font_file": "BlushScript.woff",
      "font_family": "BlushScript",
      "alias": "winter blush"
    },
    {
      "font_file": "BrittanySignature.woff",
      "font_family": "BrittanySignature-Regular",
      "alias": "bethany scroll"
    },
    {
      "font_file": "Brooldy.woff",
      "font_family": "Brooldy",
      "alias": "recent memory"
    },
    {
      "font_file": "BubblegumSans-Regular.woff",
      "font_family": "BubblegumSans-Regular",
      "alias": "candy print"
    },
    {
      "font_file": "Butterfly Garden.woff",
      "font_family": "ButterflyGarden",
      "alias": "luna moth"
    },
    {
      "font_file": "Camping.woff",
      "font_family": "CampingRegular",
      "alias": "mountain type"
    },
    {
      "font_file": "Celestial Script.woff",
      "font_family": "CelestialScript",
      "alias": "starlight script"
    },
    {
      "font_file": "CherrySwash-Regular.woff",
      "font_family": "CherrySwash-Regular",
      "alias": "orange slab"
    },
    {
      "font_file": "Comfortaa-Regular.woff",
      "font_family": "Comfortaa-Regular",
      "alias": "modern print"
    },
    {
      "font_file": "Copse-Regular.woff",
      "font_family": "Copse",
      "alias": "author type"
    },
    {
      "font_file": "Crushed.woff",
      "font_family": "Crushed-Regular",
      "alias": "vibrant rainbow"
    },
    {
      "font_file": "EBGaramond-Regular.woff",
      "font_family": "EBGaramond",
      "alias": "garamond"
    },
    {
      "font_file": "Elosthin.woff",
      "font_family": "Elosthin",
      "alias": "mayberry script"
    },
    {
      "font_file": "Elowen Caps.woff",
      "font_family": "ElowenCaps",
      "alias": "boho caps"
    },
    {
      "font_file": "Elowen.woff",
      "font_family": "Elowen",
      "alias": "boho fancy"
    },
    {
      "font_file": "Fadhillah Signature.woff",
      "font_family": "FadhillahSignature",
      "alias": "familiar signature"
    },
    {
      "font_file": "Fronds Getturing.woff",
      "font_family": "Fronds Getturing",
      "alias": "trusted marker"
    },
    {
      "font_file": "Garage Regular.woff",
      "font_family": "GarageRegular",
      "alias": "whiskey print"
    },
    {
      "font_file": "GingerGinAged.woff",
      "font_family": "GingerGinAged",
      "alias": "blue tonic"
    },
    {
      "font_file": "GreatVibes-Regular.woff",
      "font_family": "GreatVibes-Regular",
      "alias": "fancy vibe"
    },
    {
      "font_file": "Hamidha.woff",
      "font_family": "Hamidha",
      "alias": "purple haze script"
    },
    {
      "font_file": "HomemadeApple.woff",
      "font_family": "HomemadeApple",
      "alias": "farmers signature"
    },
    {
      "font_file": "Houston.woff",
      "font_family": "Houston-Regular",
      "alias": "education sans"
    },
    {
      "font_file": "Irendy.woff",
      "font_family": "Irendy",
      "alias": "trademark script"
    },
    {
      "font_file": "Koffins.woff",
      "font_family": "koffins-Regular",
      "alias": "new york city type"
    },
    {
      "font_file": "LifeSavers-Bold.woff",
      "font_family": "LifeSavers-Bold",
      "alias": "wild world"
    },
    {
      "font_file": "Love Rose.woff",
      "font_family": "loverose",
      "alias": "romantic signature"
    },
    {
      "font_file": "Love moment.woff",
      "font_family": "Lovemoment",
      "alias": "violet hand"
    },
    {
      "font_file": "LuxuriousScript-Regular.woff",
      "font_family": "LuxuriousScript-Regular",
      "alias": "matrimony script"
    },
    {
      "font_file": "Magethai.woff",
      "font_family": "Magethai",
      "alias": "magellan type"
    },
    {
      "font_file": "Melinda.woff",
      "font_family": "Melinda",
      "alias": "melissa script"
    },
    {
      "font_file": "Miabella.woff",
      "font_family": "Miabella",
      "alias": "meridian script"
    },
    {
      "font_file": "Mikina Sweet.woff",
      "font_family": "MikinaSweet",
      "alias": "little letters type"
    },
    {
      "font_file": "Monten.woff",
      "font_family": "Monten",
      "alias": "blue block type"
    },
    {
      "font_file": "Montez-Regular.woff",
      "font_family": "Montez-Regular",
      "alias": "penelope signature"
    },
    {
      "font_file": "MoonlessSC-Regular-_1_.woff",
      "font_family": "MoonlessSC-Regular",
      "alias": "marshmallow type"
    },
    {
      "font_file": "MountainsofChristmas.woff",
      "font_family": "MountainsofChristmas-Regular",
      "alias": "festival type"
    },
    {
      "font_file": "Nagitsya.woff",
      "font_family": "Nagitsya",
      "alias": "sunflower script"
    },
    {
      "font_file": "Oswald.woff",
      "font_family": "Oswald",
      "alias": "standard print"
    },
    {
      "font_file": "Parisienne-Regular.woff",
      "font_family": "Parisienne-Regular",
      "alias": "paris signature"
    },
    {
      "font_file": "Pompiere-Regular.woff",
      "font_family": "Pompiere-Regular",
      "alias": "london haze print"
    },
    {
      "font_file": "Praise-Regular.woff",
      "font_family": "Praise-Regular",
      "alias": "plenty bold script"
    },
    {
      "font_file": "QuickOrDeadAged.woff",
      "font_family": "QuickOrDead-Aged",
      "alias": "quick draw type"
    },
    {
      "font_file": "Restylia.woff",
      "font_family": "Restylia",
      "alias": "swift river script"
    },
    {
      "font_file": "Ronsley-Sans.woff",
      "font_family": "RonsleySans",
      "alias": "conway caps"
    },
    {
      "font_file": "Ronsley-Script.woff",
      "font_family": "Ronsley",
      "alias": "crimson script"
    },
    {
      "font_file": "RougeScript-Regular.woff",
      "font_family": "RougeScript-Regular",
      "alias": "amelia script"
    },
    {
      "font_file": "Rye-Regular.woff",
      "font_family": "Rye-Regular",
      "alias": "cowboy letters"
    },
    {
      "font_file": "Sakamoto.woff",
      "font_family": "Sakamoto",
      "alias": "desert bubbles"
    },
    {
      "font_file": "Sathilda.woff",
      "font_family": "Sathilda",
      "alias": "samantha signature"
    },
    {
      "font_file": "Seattle.woff",
      "font_family": "SeattleScript",
      "alias": "emily letters"
    },
    {
      "font_file": "Sella Sionisa.woff",
      "font_family": "SellaSionisa",
      "alias": "yellow cursive"
    },
    {
      "font_file": "SendFlowers-Regular.woff",
      "font_family": "SendFlowers-Regular",
      "alias": "simple signature"
    },
    {
      "font_file": "Special Girl Bold.woff",
      "font_family": "SPECIALGIRL",
      "alias": "farmhouse chunky"
    },
    {
      "font_file": "SpecialElite.woff",
      "font_family": "SpecialElite-Regular",
      "alias": "typewriter print"
    },
    {
      "font_file": "SpectralSC-Regular.woff",
      "font_family": "SpectralSC-Regular",
      "alias": "old fashioned small caps"
    },
    {
      "font_file": "Srianty.woff",
      "font_family": "Srianty",
      "alias": "raven type"
    },
    {
      "font_file": "StyleScript-Regular.woff",
      "font_family": "StyleScript-Regular",
      "alias": "stylish script"
    },
    {
      "font_file": "Timbra_Sans_Regular.woff",
      "font_family": "TimbraSans",
      "alias": "timber print"
    },
    {
      "font_file": "TrashHand.woff",
      "font_family": "TrashHand",
      "alias": "free form letters"
    },
    {
      "font_file": "Vanilla.woff",
      "font_family": "Vanilla",
      "alias": "space bubble"
    },
    {
      "font_file": "Whilona Script.woff",
      "font_family": "WhilonaScript",
      "alias": "willow script"
    },
    {
      "font_file": "Wisteria.woff",
      "font_family": "Wisteria",
      "alias": "grapevine fancy"
    },
    {
      "font_file": "Young Pink.woff",
      "font_family": "YoungPink",
      "alias": "hen house type"
    },
    {
      "font_file": "Yustia.woff",
      "font_family": "Yustia",
      "alias": "retro sign script"
    },
    {
      "font_file": "eb-garamond.12-regular-all-smallcaps.woff",
      "font_family": "EBGaramond12-AllSC",
      "alias": "garamond small caps"
    },
    {
      "font_file": "margheritavariablegx.woff",
      "font_family": "MargheritaVariable",
      "alias": "library book type"
    },
    {
      "font_file": "mf854385.woff",
      "font_family": "JustMarried",
      "alias": "honeymoon script"
    },
    {
      "font_file": "norwester.woff",
      "font_family": "Norwester-Regular",
      "alias": "pacific block"
    }
  ]

export default fonts