import React from 'react';

const NudgeIcon = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_9_328)">
<path d="M30.3853 15.7014L26.676 19.4107C26.4926 19.5932 26.2528 19.6853 26.0131 19.6853C25.7733 19.6853 25.5335 19.5932 25.3502 19.4107C24.9835 19.044 24.9835 18.4507 25.3502 18.085L27.5382 15.896H16.6368V26.7993L18.789 24.648C19.1548 24.2813 19.7481 24.2813 20.1148 24.648C20.4806 25.0138 20.4806 25.6071 20.1148 25.9738L16.3641 29.7235C16.335 29.7536 16.2964 29.7659 16.2635 29.7903C16.1986 29.8401 16.1366 29.8937 16.0604 29.9257C15.9457 29.9727 15.8234 29.9981 15.7012 29.9981C15.7012 29.9981 15.7003 29.9981 15.6993 29.9981C15.6881 29.9981 15.6796 29.9925 15.6683 29.9915C15.4398 29.984 15.2132 29.8984 15.0383 29.7235L11.2886 25.9738C10.9219 25.6071 10.9219 25.0138 11.2886 24.648C11.6543 24.2813 12.2476 24.2813 12.6143 24.648L14.808 26.8416V15.896H3.86144L6.05131 18.085C6.41707 18.4507 6.41707 19.044 6.05131 19.4107C5.8689 19.5932 5.62819 19.6853 5.38842 19.6853C5.14865 19.6853 4.90795 19.5932 4.72554 19.4107L0.972951 15.66C0.797122 15.4842 0.698395 15.2463 0.698395 14.9972C0.698395 14.8721 0.723782 14.7499 0.771735 14.6361C0.818748 14.5223 0.888328 14.4189 0.973892 14.3333L4.72554 10.5845C5.0913 10.2178 5.68554 10.2178 6.05131 10.5845C6.41707 10.9503 6.41707 11.5436 6.05131 11.9103L3.89999 14.0597H14.808V3.15079L12.6125 5.34725C12.2458 5.71301 11.6524 5.71301 11.2867 5.34725C10.92 4.98054 10.92 4.38724 11.2867 4.02148L15.0195 0.287697C15.1784 0.108107 15.4116 -0.00284392 15.6984 -0.00378418C15.8206 -0.00378418 15.9429 0.0206626 16.0576 0.068616C16.1732 0.115629 16.2767 0.185208 16.3632 0.271713L20.1129 4.02148C20.4787 4.38724 20.4787 4.98054 20.1129 5.34725C19.9296 5.52966 19.6898 5.6218 19.45 5.6218C19.2103 5.6218 18.9705 5.52966 18.7872 5.34725L16.6368 3.19687V14.0597H27.4996L25.3502 11.9103C24.9835 11.5436 24.9835 10.9503 25.3502 10.5845C25.7159 10.2178 26.3093 10.2178 26.676 10.5845L30.4248 14.3333C30.5113 14.4198 30.5809 14.5242 30.6288 14.6389C30.6758 14.7536 30.7003 14.8759 30.7003 14.9981C30.7003 15.298 30.579 15.5406 30.3853 15.7014Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_9_328">
<rect width="30" height="30" fill="white" transform="translate(0.704041)"/>
</clipPath>
</defs>
</svg>

  );
};

export default NudgeIcon;
