import Debug from "@/pages/Maker/components/Headers/Debug";
import { forwardRef, useState } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { useRecoilState } from "recoil";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

import {Button} from "@/components/Button";

// These features are in active development
import ToggleEdgeButton from "@/components/ToggleEdgeButton/ToggleEdgeButton";
import axios from "axios";
import ZoomButton from "@/pages/Maker/components/UIControls/ZoomButton";
import PanControls from "@/pages/Maker/components/UIControls/PanControls";
import ButtonGroup from "@/components/ButtonGroup";

// signals
import { canvas } from "@/signals/canvas";

// This is for add to cart 
import AddToCartButton from "./AddToCartButton";


const ShopifyHeader = forwardRef((props, ref) => {

  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [debug, setDebug] = useState(false)
  

  const handleClose = () => {
    var app = document.querySelector('.app-outer') || document.querySelector('#peachwik-app');
    app?.classList.toggle('hidden');
    document.body.style.overflow = 'unset';
  
    // Unmount the React app
    window.removeReactApp();
  }


  

  return (
    
    <header ref={ref}>
      <AppBar position="static">
        <Debug 
          visible={debug}
          setDebug={setDebug}
          />

        {/* align content of toolbar on the right */}
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          
          <ButtonGroup>
          <Button
            variant="navy"
            icon={<CloseIcon />}
            onClick={handleClose}
          >
            Close Without Saving
          </Button>
          <AddToCartButton />
     
          </ButtonGroup>
        </Toolbar>

      </AppBar>
    </header>
  );
})

ShopifyHeader.displayName = "ShopifyHeader";
export default ShopifyHeader;