export function renameSvgId(svgContent, originalId, newId) {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");

  const element = svgDoc.getElementById(originalId);
  if (element) {
      element.id = newId; // Rename the ID
  }

  return serializer.serializeToString(svgDoc);
}
