// MainSwatchStack.js
import React, { useRef, lazy, Suspense } from "react";
import { Paper, Toolbar } from "@mui/material";
import { useRecoilValue } from "recoil";
import { configSelector } from "@/atoms/config-atom";
import * as swatchData from './swatchData.js';  // Import swatch data
import Processing from "@/components/Processing/Processing.jsx";
import { useSkuConfig } from "@/hooks/useSkuConfig.js";

const MainSwatchStack = ({ includeSwatches = true }) => {
  const { config } = useSkuConfig();
  // const config = useRecoilValue(configSelector);
  const PaperRef = useRef(null);

  const COMPONENT_MAP = {
    'FrameSwatchesStack': lazy(() => import('./SwatchStack.jsx')),
    'FloralSwatchesStack': lazy(() => import('./SwatchStack.jsx')),
    'SwatchesStack': lazy(() => import('./SwatchStack.jsx')),
    'ColorStack': lazy(() => import('./ColorStack.jsx')),
    // ... add other components here
  };

  console.log('config.swatch_options')
  console.log(config)

  return (
    <>
      <Toolbar>
        <Paper
          ref={PaperRef}
          style={{
            height: '200px',
            width: '100%',
            overflow: 'auto',
            marginBottom: '10px',
          }}>
          <Suspense fallback={<Processing message="Loading..." />}>
            {config.swatch_options.map((option, i) => {
              const Component = COMPONENT_MAP[option.component];
              const swatches = swatchData[config[option.swatches]];
              
              // Condition to check if image swatches should be included
              if (!includeSwatches && (option.component !== 'ColorStack')) {
                return null;  // Return null to render nothing if image swatches shouldn't be included
              }

              return Component ? (
                <Component
                  key={option.name}
                  title={option.name}
                  swatches={option.swatches}
                />
              ) : null;
            })}
          </Suspense>
        </Paper>
      </Toolbar>
    </>
  );
}

export default MainSwatchStack;
