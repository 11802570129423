import { Button, Toolbar } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import canvasAtom from "@/atoms/canvas-atom";
import axios from "axios";
import { templatesSelector } from "../../../../atoms/config-atom";


const TemplatesToolbar = ({visible}) => {
  const [canvas, setCanvas] = useRecoilState(canvasAtom)
  // load the config api for this file and get the templates array
  const templates = useRecoilValue(templatesSelector)

  const handleClick = async (url) => {

    canvas.clear()
    const res = await axios.get(url)
    const template = res.data

    canvas.loadFromJSON(template, () => {
      canvas.renderAll()
    })

  }

  return ( templates && <>
    <Toolbar>
      {templates.map((template, index) => (
        <Button variant='contained'
          onClick={()=>handleClick(template.template_url)}
        >
          {template.template_name}
        </Button>
      ))}
    </Toolbar>
  </> );
}
 
export default TemplatesToolbar;