import React from 'react';

const AllCapsIcon = () => {
  return (
    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0958 19.7041H14.2492L19.5901 5.1586H21.4083L26.7492 19.7041H24.9026L20.556 7.45974H20.4424L16.0958 19.7041ZM16.7776 14.0222H24.2208V15.5847H16.7776V14.0222Z" fill="white"/>
<path d="M2.58019 19.7041H0.733597L6.07451 5.1586H7.89269L13.2336 19.7041H11.387L7.04042 7.45974H6.92678L2.58019 19.7041ZM3.26201 14.0222H10.7052V15.5847H3.26201V14.0222Z" fill="white"/>
</svg>

  );
};

export default AllCapsIcon;
