import React from 'react';

const TitleCaseIcon = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4822 19.9597C17.7909 19.9597 17.1635 19.8295 16.6001 19.5691C16.0366 19.304 15.5892 18.9228 15.2577 18.4256C14.9263 17.9238 14.7606 17.3177 14.7606 16.6075C14.7606 15.9825 14.8837 15.4758 15.1299 15.0876C15.3761 14.6946 15.7052 14.3868 16.1171 14.1643C16.5291 13.9417 16.9836 13.776 17.4808 13.6671C17.9827 13.5535 18.4869 13.4635 18.9935 13.3972C19.6564 13.312 20.1938 13.2481 20.6058 13.2055C21.0224 13.1581 21.3255 13.08 21.5149 12.9711C21.709 12.8622 21.806 12.6728 21.806 12.4029V12.3461C21.806 11.6453 21.6143 11.1008 21.2308 10.7126C20.852 10.3243 20.2767 10.1302 19.5049 10.1302C18.7047 10.1302 18.0774 10.3054 17.6228 10.6558C17.1683 11.0061 16.8487 11.3802 16.664 11.7779L15.0731 11.2097C15.3572 10.5469 15.736 10.0308 16.2095 9.66145C16.6877 9.28739 17.2085 9.02698 17.772 8.8802C18.3401 8.72868 18.8988 8.65292 19.4481 8.65292C19.7985 8.65292 20.2009 8.69554 20.6555 8.78076C21.1148 8.86126 21.5575 9.02934 21.9836 9.28502C22.4145 9.54071 22.772 9.9266 23.056 10.4427C23.3401 10.9588 23.4822 11.6501 23.4822 12.5166V19.7041H21.806V18.2268H21.7208C21.6072 18.4635 21.4178 18.7168 21.1526 18.9867C20.8875 19.2566 20.5347 19.4863 20.0944 19.6756C19.6541 19.865 19.1167 19.9597 18.4822 19.9597ZM18.7379 18.4541C19.4007 18.4541 19.9595 18.3239 20.414 18.0634C20.8733 17.803 21.2189 17.4668 21.4509 17.0549C21.6877 16.643 21.806 16.2097 21.806 15.7552V14.2211C21.735 14.3063 21.5788 14.3845 21.3373 14.4555C21.1006 14.5218 20.8259 14.581 20.5134 14.633C20.2057 14.6804 19.905 14.723 19.6114 14.7609C19.3226 14.794 19.0882 14.8224 18.9083 14.8461C18.4727 14.9029 18.0655 14.9953 17.6867 15.1231C17.3127 15.2462 17.0096 15.4332 16.7776 15.6842C16.5504 15.9304 16.4367 16.2666 16.4367 16.6927C16.4367 17.2751 16.6522 17.7154 17.083 18.0137C17.5186 18.3073 18.0702 18.4541 18.7379 18.4541Z" fill="white"/>
<path d="M2.58019 19.7041H0.733597L6.07451 5.1586H7.89269L13.2336 19.7041H11.387L7.04042 7.45974H6.92678L2.58019 19.7041ZM3.26201 14.0222H10.7052V15.5847H3.26201V14.0222Z" fill="white"/>
</svg>

  );
};

export default TitleCaseIcon;
