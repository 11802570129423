import styles from './FontSizeOrb.module.css'

import React from "react";

export const FontSizeOrb = ({ fontSize = "50" }) => {
  return (
    <div className={styles.orb}>
      <span className="tw-text-3xl">{fontSize}</span>
    </div>
  );
};
