import * as fabric from 'fabric';
// fabricConfig.ts

export const useFabricConfig = () => {
  fabric.Object.prototype.statefullCache = true; // This is important for performance
  fabric.Object.prototype.objectCaching = false // This is important for exporting hi res backgrounds
  fabric.Object.NUM_FRACTION_DIGITS = 9; // This is important for precision when serializing objects
  
  fabric.Object.prototype.toObject = (function (toObject) {
    return function (propertiesToInclude) {
      const properties = (propertiesToInclude || []).concat([
        'id', 
        'selectable', 
        'clipPathID', // this is so we can use placeholder images
        'patternMetaData'// this is so we can replace patterns and scale them
      ]); // Add any other properties you want to include for serialization here
      return toObject.apply(this, [properties]);
    };
  })(fabric.Object.prototype.toObject);
  
};
