import React from 'react';
import styles from './PreviewPane.module.css';

const PreviewPane = ({ svgContent }) => {
  return (
    <div className={styles.previewPane}>
      <h1 className={styles.title}>Preview Pane</h1>
      <div className={styles.svgContainer}>
        {/* Ensure SVG scales within this div without clipping */}
        {svgContent && (
          <div
            dangerouslySetInnerHTML={{ __html: svgContent }}
            className={styles.svgContent}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewPane;
