import { useEffect } from 'react';

export const useViewportHeight = () => {
  useEffect(() => {
    const setVHVariable = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVHVariable(); // Initial set

    window.addEventListener('resize', setVHVariable); // Update on resize

    // Cleanup
    return () => {
      window.removeEventListener('resize', setVHVariable);
    };
  }, []);
};