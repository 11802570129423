import React, { useState } from 'react';

const SvgFileName = ({svgFileName}) => {
  const [localSvgFileName, setLocalSvgFileName] = useState(svgFileName);
  return (
  <input type="text" 
    defaultValue={svgFileName} 
    name="svgFileName"
    onChange={(e) => setLocalSvgFileName(e.target.value)} placeholder='Enter a Filename' />
)}

export default SvgFileName;