// isCanvasReadyAtom.js

// This atom is used to keep track of whether the canvas is ready or not
// and not just if a canvas object is created, because this happens BEFORE
// the canvas is ready to be used. This is used to prevent the use of the
// canvas object before it's ready. This can happen becuase the canvas is
// created by fabric and then we have to load our artwork onto it which
// takes time. This atom is used to prevent the use of the canvas object
// before it's ready.

import { atom } from 'recoil';

const isCanvasReadyAtom = atom({
  key: 'isCanvasReady',
  default: false
});

export default isCanvasReadyAtom;
