import styles from './Icon.module.css'

const ColorIcon = () => {
  return <svg
    fill="none"
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path

      d="M17.5 15.6251C17.5 16.6606 16.6605 17.5001 15.625 17.5001C14.5895 17.5001 13.75 16.6606 13.75 15.6251C13.75 14.5896 14.5895 13.7501 15.625 13.7501C16.6605 13.7501 17.5 14.5896 17.5 15.6251Z"
      fill="white"
    />
    <path

      d="M20.625 12.5001C21.6605 12.5001 22.5 11.6607 22.5 10.6251C22.5 9.5896 21.6605 8.75012 20.625 8.75012C19.5895 8.75012 18.75 9.5896 18.75 10.6251C18.75 11.6607 19.5895 12.5001 20.625 12.5001Z"
      fill="white"
    />
    <path

      d="M16.25 8.12515C16.25 9.16069 15.4105 10.0002 14.375 10.0002C13.3395 10.0002 12.5 9.16069 12.5 8.12515C12.5 7.08963 13.3395 6.25015 14.375 6.25015C15.4105 6.25015 16.25 7.08963 16.25 8.12515Z"
      fill="white"
    />
    <path

      d="M9.375 15.0001C10.4105 15.0001 11.25 14.1606 11.25 13.1251C11.25 12.0896 10.4105 11.2501 9.375 11.2501C8.33946 11.2501 7.5 12.0896 7.5 13.1251C7.5 14.1606 8.33946 15.0001 9.375 15.0001Z"
      fill="white"
    />
    <path

      d="M17.5 21.8751C17.5 22.9106 16.6605 23.7501 15.625 23.7501C14.5895 23.7501 13.75 22.9106 13.75 21.8751C13.75 20.8396 14.5895 20.0001 15.625 20.0001C16.6605 20.0001 17.5 20.8396 17.5 21.8751Z"
      fill="white"
    />
    <path

      d="M9.375 21.2501C10.4105 21.2501 11.25 20.4106 11.25 19.3751C11.25 18.3396 10.4105 17.5001 9.375 17.5001C8.33946 17.5001 7.5 18.3396 7.5 19.3751C7.5 20.4106 8.33946 21.2501 9.375 21.2501Z"
      fill="white"
    />
    <path

      clipRule="evenodd"
      d="M14.3771 1.27769C19.2561 0.989686 24.4545 2.90555 27.2609 6.76414C28.1355 7.96655 28.5285 9.54535 28.3111 11.0394C28.0888 12.5675 27.2168 14.0394 25.559 14.8681C24.5486 15.3734 23.4664 16.1833 22.8001 17.085C22.1448 17.9723 22.0174 18.7396 22.368 19.4411C23.0289 20.7628 23.6118 22.4071 23.5133 23.9844C23.4624 24.7969 23.2285 25.6183 22.708 26.3499C22.186 27.0834 21.431 27.649 20.4643 28.0358C18.2619 28.9166 15.7849 28.9554 13.5101 28.5259C11.2322 28.0956 9.05608 27.1776 7.40821 26.0243C5.00711 24.3435 3.0932 22.1111 2.14215 19.308C1.18801 16.4956 1.2449 13.2314 2.57531 9.57254C4.55445 4.12965 9.45311 1.56837 14.3771 1.27769ZM14.5245 3.77335C10.3324 4.02082 6.48105 6.147 4.92481 10.4269C3.75518 13.6435 3.76736 16.317 4.50961 18.5048C5.25495 20.7016 6.77853 22.5319 8.84185 23.9761C10.1801 24.9129 12.0211 25.7005 13.974 26.0693C15.9303 26.4388 17.8976 26.3699 19.5358 25.7145C20.385 25.3749 20.9598 24.7624 21.0181 23.8284C21.0758 22.9056 20.7211 21.7376 20.132 20.5591C19.2049 18.7049 19.8178 16.9148 20.7894 15.5995C21.7503 14.2989 23.1819 13.2616 24.441 12.6321C25.2833 12.211 25.7184 11.4953 25.8371 10.6794C25.9608 9.82956 25.7289 8.90798 25.2391 8.23462C23.0455 5.21858 18.7616 3.52323 14.5245 3.77335Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>

}

export default ColorIcon;
