import React, { useState } from 'react';
import { renameSvgId } from '../../utils'; // Adjust the import path as necessary
import styles from './LayerObject.module.css';

const layerColors = {
    color: 'tw-bg-blue-200',
    text: 'tw-bg-green-200',
    photo: 'tw-bg-red-200',
};

const LayerObject = ({ layer, svgFileName, svgContent, updateSvgContentAndParseIds, addOption, saveSvgToFile }) => {
    const [layerName, setLayerName] = useState(layer.name);

    const handleNameChange = (event) => {
        setLayerName(event.target.value);
    };

    const handleRename = () => {
        // Perform client-side SVG ID renaming
        const updatedSvgContent = renameSvgId(svgContent, layer.name, layerName);
        updateSvgContentAndParseIds(updatedSvgContent); // Update state with new SVG content
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleRename();
            saveSvgToFile();
        }
    };

    return (
        <div className={styles.layer}>
            <div className={styles.handle}>=</div>
            <input
              type="text"
              className={`${styles.layerName} ${layerColors[layer.type] || ''}`}
              value={layerName}
              onChange={handleNameChange}
              onKeyPress={handleKeyPress}
            />
            <button className={styles.addButton}onClick={addOption}>+</button>
        </div>
    );
};

export default LayerObject;
