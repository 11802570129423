import React from 'react';

const FormatIcon = () => {
  return (
  <svg width="44" height="30" viewBox="0 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.087 2.6065H41.5922C42.6728 2.6065 43.5488 3.48252 43.5488 4.56314C43.5488 5.64377 42.6728 6.51978 41.5921 6.51978H12.0888C11.5067 8.0635 10.2565 9.13098 8.80808 9.13098C7.35966 9.13098 6.10943 8.0635 5.52736 6.51978H1.95664C0.876014 6.51978 0 5.64377 0 4.56314C0 3.48252 0.876017 2.6065 1.95664 2.6065H5.52914C6.11196 1.06532 7.36113 0 8.80808 0C10.255 0 11.5042 1.06532 12.087 2.6065ZM1.95664 13.0438C0.876017 13.0438 0 13.9199 0 15.0005C0 16.0811 0.876014 16.9571 1.95664 16.9571H32.4958C33.0779 18.5008 34.3281 19.5683 35.7766 19.5683C37.225 19.5683 38.4752 18.5008 39.0573 16.9571H41.5921C42.6728 16.9571 43.5488 16.0811 43.5488 15.0005C43.5488 13.9199 42.6728 13.0438 41.5922 13.0438H39.0555C38.4727 11.5027 37.2235 10.4373 35.7766 10.4373C34.3296 10.4373 33.0804 11.5027 32.4976 13.0438H1.95664ZM0 25.4359C0 24.3553 0.876017 23.4793 1.95664 23.4793H19.0157C19.5979 21.9361 20.848 20.869 22.2961 20.869C23.7442 20.869 24.9943 21.9361 25.5765 23.4793H41.5922C42.6728 23.4793 43.5488 24.3553 43.5488 25.4359C43.5488 26.5166 42.6728 27.3926 41.5921 27.3926H25.5754C24.9927 28.9343 23.7433 30 22.2961 30C20.8489 30 19.5995 28.9343 19.0168 27.3926H1.95664C0.876014 27.3926 0 26.5166 0 25.4359Z" fill="white"/>
  </svg>
  );
};

export default FormatIcon;
