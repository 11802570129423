import React from 'react';

const RabbitIcon = () => {
  return (
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.1334 24.298C23.3392 22.9997 23.2182 21.5272 22.4852 19.9124C20.4673 15.4638 16.0115 15.0589 12.9054 13.5736C11.782 13.0368 11.0473 12.1894 10.6429 11.1091C10.6429 11.1091 10.0443 9.55902 10.3391 8.47626C10.6429 7.36199 11.7058 6.16544 12.095 4.66204C13.0736 0.881042 12.2638 -1.14414 9.73145 0.678248C7.13434 2.54909 7.09866 7.22763 7.09866 7.22763C7.09866 7.22763 7.16645 7.39591 7.03145 7.66708C6.81899 8.09441 5.99346 7.94916 6.08604 4.32431C6.14839 1.89067 4.06028 -1.65068 2.44069 2.60241C1.62119 4.75163 3.85936 8.44246 3.85936 8.44246C3.85936 8.44246 2.19858 9.43082 1.12367 11.1092C-0.496037 13.6408 -0.0105869 14.7738 0.414863 15.7676C1.32635 17.8945 3.75641 15.8687 5.07342 20.3246C5.57698 22.0271 5.47168 27.0828 4.62067 27.812C3.76971 28.542 4.37797 29.7574 4.37797 29.7574H8.02339C8.02339 29.7574 8.19893 26.0648 9.25925 26.6706C11.1501 27.7503 12.7625 27.5694 11.426 28.0554C10.0898 28.5408 10.2108 30 10.2108 30H19.4475C19.8125 29.7712 20.6889 29.0734 21.5053 27.9972C21.8794 28.3235 22.3617 28.5274 22.8968 28.5274C24.0722 28.5274 25.0236 27.5748 25.0236 26.4006C25.0236 25.3075 24.1949 24.4166 23.1334 24.298Z" fill="white"/>
</svg>

  );
};

export default RabbitIcon;
