
import { useEffect, useState } from "react"
// components
import { Toolbar } from "@mui/material"
import { FontSizeOrb } from "./FontSizeOrb"
import OrbButton from "./OrbButton"
// Signals
import { canvas } from "@/signals/canvas"

const FontSizeToolbar = () => {
  let activeObject = canvas.value.getActiveObject();
  // Check if it's a group and pick the first object
  if (activeObject && activeObject._objects && activeObject._objects.length > 0) {
    activeObject = activeObject._objects[0];
  }
  // const activeObject = canvas.value.getActiveObject()
  const [fontSize, setFontSize] = useState(Math.ceil(activeObject.get('fontSize')))

  useEffect(()=>{
    activeObject.set('fontSize', fontSize)
    canvas.value.renderAll()
  }, [fontSize])

  // center paper in toolbar
  return <Toolbar className="tw-justify-center tw-items-center tw-gap-2 tw-m-2"> 
    <OrbButton
      onClick={()=>setFontSize(fontSize - 1)}
    >-</OrbButton>
      <FontSizeOrb fontSize={fontSize} />
      <OrbButton
      onClick={()=>setFontSize(fontSize + 1)}
    >+</OrbButton>
  </Toolbar>
}

export default FontSizeToolbar