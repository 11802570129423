// fabricConfig.js
import * as fabric from 'fabric';

function useFabricTextControls(canvas) {
    const deleteIcon = `${import.meta.env.VITE_CLOUDFRONT_CDN_APP_IMAGES_URL}/delete-icon-v2.svg`; // URL to your delete icon

    // Load the delete icon image
    const img = new Image();
    img.onload = function () {
        // Define the delete control specifically for fabric.Text
        const deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 0, // Adjust based on the size of the control
            cursorStyle: 'pointer',
            mouseUpHandler: function(eventData, target) {
                canvas.value.remove(canvas.value.getActiveObject());
                canvas.value.requestRenderAll();
            },
            render: function(ctx, left, top, styleOverride, fabricObject) {
                const size = this.cornerSize;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(img, -size / 2, -size / 2, size, size);
                ctx.restore();
            },
            cornerSize: 24
        });

        // Extend fabric.Text to include the delete control by default
        fabric.Text.prototype.controls = {
            ...fabric.Object.prototype.controls,
            deleteControl: deleteControl
        };
    };
    img.src = deleteIcon;
}

export { useFabricTextControls };
