import React from "react";
import styles from "./OptionItem.module.css";
import Chip from "./Chip";
import ChipGroup from "./ChipGroup";
import DragHandle from "../DragHandle";
import IconBox from "@/components/IconBox";
import { Draggable } from 'react-beautiful-dnd';

const OptionItem = React.memo(({
  option,
  optionType,
  index,
  handleDeleteOption,
  handleNameChange,
  handleMemberChange,
  handleDeleteMember
}) => {
  console.log('OptionItem');
  const onKeyDownNameInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const nameInput = e.target.value || '';
      handleNameChange(optionType, index, nameInput);
    }
  };


  const onKeyDownMemberInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const memberInput = e.target.value || '';
      const newMembers = memberInput.split(',')
        .map(member => member.trim())
        .filter(member => member.length > 0);

      if (newMembers.length > 0) {
        handleMemberChange(optionType, index, newMembers);
      }

      e.target.value = ''; // Clear the input field
    }
  };

  return (
    <Draggable draggableId={`draggable-${optionType}-${index}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${styles.option} ${snapshot.isDragging ? 'dragging' : ''}`}
        >
          <div className={styles.optionLine}>
            <DragHandle />
            <IconBox icon={optionType} />
            <div className={styles.labelInputPair}>
              <label className={styles.label}>NAME {`[ ${option.name} ]`}</label>
              <input
                className={styles.textInput}
                type="text"
                defaultValue={option.name}
                onKeyDown={onKeyDownNameInput}
              />
            </div>
            <div className={styles.labelInputPair}>
              <label className={styles.label}>ADD MEMBER</label>
              <input
                className={styles.textInput}
                onKeyDown={onKeyDownMemberInput}
                type="text"
                defaultValue={option.members}
                placeholder="PRESS [ENTER] TO ADD. You can add multiples if you separate with comma."
              />
            </div>
            <ChipGroup>
              {option.members.map((member, memberIndex) => (
                <Chip
                  key={memberIndex}
                  label={member}
                  onDelete={() => handleDeleteMember(optionType, index, memberIndex)}
                />
              ))}
            </ChipGroup>
            <button
              className={styles.deleteButton}
              onClick={() => handleDeleteOption(optionType, index)}
            >x</button>
          </div>
        </div>
      )}
    </Draggable>
  );
});

export default OptionItem;
// export default React.memo(OptionItem);