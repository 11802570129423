import { useEffect } from 'react';
import { debounce } from '@/utils/debounce';

// signals
import { canvas } from '@/signals/canvas'; 

const useResizeCanvas = (boxRef, loading) => {
  useEffect(() => {
    if (!canvas.value) return;
    if(loading) return;

    const debouncedHandleResize = debounce(() => handleResize(boxRef, canvas), 300);

    // Set canvas dimensions on initial load
    handleResize(boxRef, canvas);

    // Add event listener for resize and call debouncedHandleResize
    window.addEventListener('resize', debouncedHandleResize);
    // Add a custom even listener to trigger resize
    window.addEventListener('resize-canvas', debouncedHandleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [canvas.value, boxRef, loading]); // Dependencies: Re-run effect if canvas or boxRef changes

}

export default useResizeCanvas;


function handleResize(boxRef, canvas) {
  if (boxRef.current && canvas.value) {
    const { height, width } = boxRef.current.getBoundingClientRect();

    const heightScale = height / canvas.value.height;
    const widthScale = width / canvas.value.width;

    let scale;
    if (widthScale < heightScale) {
      scale = width / canvas.value.width;
    } else {
      scale = height / canvas.value.height;
    }

    // Set canvas dimensions
    canvas.value.setDimensions({
      width: canvas.value.width * scale,
      height: canvas.value.height * scale
    });

    // Update canvas zoom
    const newZoom = scale * canvas.value.getZoom();
    canvas.value.setZoom(newZoom);

    // Additional adjustments
    canvas.value.calcOffset();
    canvas.value.renderAll();
  }
}
